import React, { useState } from "react";
import { Badge, Button, Card, message, Spin, Switch, Typography } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { loginUser, orgMember, Plan } from "../../../type/type";
import { connect } from "react-redux";
import { createCheckoutSession } from "../../../lib/api";
import { newOrganisation } from "../../../lib/apiRes";
import { OWNER } from "../../../constant";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
const { Text } = Typography;
// Define types for Redux state
interface PricingTableProps {
  plans: Plan[];
  stripeCustomerID: string;
  currentOrg: newOrganisation;
  loading: boolean;
  members: orgMember;
  loginUser: loginUser;
  applicationId: string;
}

const PricingTable: React.FC<PricingTableProps> = ({
  currentOrg,
  plans,
  loading,
  stripeCustomerID,
  members,
  loginUser,
  applicationId,
}) => {
  const [billingInterval, setBillingInterval] = useState<"month" | "year">(
    "month"
  );
  const [loadingPlanId, setLoadingPlanId] = useState<string | null>(null);

  const filteredPlans = plans.filter(
    (plan) => plan.interval === billingInterval && plan.active
  );

  const currentPlan = currentOrg.customer?.subscriptions?.data[0]?.items?.data[0]?.price?.id;
  const handleSelectPlan = async (id: string) => {
    if (currentOrg.customer?.metadata?.plan.toLowerCase() === "free" || currentOrg.customer?.metadata?.plan.toLowerCase() === "free trial") {
    setLoadingPlanId(id);
    const lineItems = [
      {
        price: id,
        quantity: 1,
        billing_cycle:billingInterval
      },
    ];
    const req = {
      customer: stripeCustomerID,
      line_items: lineItems,
      mode: "subscription",
      ui_mode: "hosted",
      success_url: `${process.env.REACT_APP_STRIPE_CHECKOUT_RETURN_URL}/o/${applicationId}/settings/billing`,
    };

    try {
      const sessionData = await createCheckoutSession(req);
      window.location.href = sessionData.url;
    } catch (error) {
      message.error("There was a problem with the checkout process.");
      console.error("Checkout error:", error);
    } finally {
      setLoadingPlanId(null);
    }
      return;
    }
    window.location.href = currentOrg.dashboard?.url;
    return;
  };

  return (
    <div>
      {loading ? (
        <div
          className="flex justify-center items-center"
          style={{ width: 900, height: 500 }}
        >
          <Spin />
        </div>
      ) : (
        <>
          <div className="flex justify-center mt-3 items-center space-x-4 mb-8">
        <Text 
          className={`text-base ${billingInterval === "month" ? "font-semibold text-gray-900" : "text-gray-500"}`}
        >
          Bill Monthly
        </Text>
        <Switch
          checked={billingInterval === "year"}
          onChange={(checked) => setBillingInterval(checked ? "year" : "month")}
          className="bg-primary"
          style={{ backgroundColor: billingInterval === "year" ? "#4B48A5" : undefined }}
        />
        <Text 
          className={`text-base ${billingInterval === "year" ? "font-semibold text-gray-900" : "text-gray-500"}`}
        >
          Bill Yearly
        </Text>
         </div>

          <div   className="pricing-cards-container grid grid-cols-3 gap-4 max-w-5xl mx-auto"
          style={{ gridAutoRows: "1fr" }} >
            {filteredPlans.map((plan) => {
              const isPremium = plan.product.name === "Growth";
              const cardContent = (
                <Card
                  key={plan.id}
                  className= {`rounded-md border border-1 flex flex-col justify-between`}
                  style={{
                    width: 301,
                    height: "100%",
                    textAlign: "left",
                    color: "#ffff",
                    border: isPremium ? "2px solid #4B48A5":"",
                    boxShadow:"rgba(7, 65, 210, 0.1) 0px 9px 30px"
                  }}
                >
                  <div className="flex justify-between">
                    <Text className="font-medium text-2xl">
                      {capitalizeFirstLetter(plan.product.name)}
                    </Text>
                  </div>
                  <div className="my-4">
                    <Text className="text-2xl font-bold">
                      {" "}
                      ${plan.amount / 100}{" "}
                    </Text>
                    <Text style={{ fontSize: "16px" }}>/ {plan.interval}</Text>
                  </div>
                  {(members[loginUser.user.id].registrations[0].roles[0] ===
                      OWNER && plan.product.name.toLowerCase() !== "enterprise" )&&(<div className="flex items-center h-10 mb-4 justify-center">
                      <Button
                        disabled = {plan.id === currentPlan}
                        key={plan.id}
                        type="primary"
                        onClick={() => handleSelectPlan(plan.id)}
                        loading={loadingPlanId === plan.id}
                        className="rounded-md my-5"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Text
                          style={{
                            color:plan.id !== currentPlan?  "#ffff":"",
                          }}
                          className="font-medium"
                        >
                         {plan.id === currentPlan?"Current plan":"Get started"}
                        </Text>
                      </Button>
                  </div>)}
                  <ul style={{ textAlign: "left" }}>
                    {plan.product.features.map((feature) => (
                      <div key={feature.name} style={{ marginBottom: "8px" }}>
                        <CheckCircleOutlined
                          style={{
                            color: "#009D79",
                            marginRight: "8px",
                          }}
                        />
                        <Text>{feature.name}</Text>
                      </div>
                    ))}
                  </ul>
                </Card>
              );

              return isPremium ? (
                <Badge.Ribbon key={plan.id} text="Most Popular" color="#4B48A5">
                  {cardContent}
                </Badge.Ribbon>
              ) : (
                cardContent
              );
            })}
          </div>
          <div className="flex justify-center items-center mt-2 space-x-1">
              <Text type="secondary">For enterprise plan</Text>
              <Button
               type="link"
               
               className="m-0 p-0 cursor-pointer"
               onClick={() => window.open('https://www.tacitbase.com', '_blank')}
              >
                <Text type="secondary" className="underline">Contact us</Text>
              </Button>
            </div>

        </>
      )}
    </div>
  );
};

// Updated mapStateToProps with TypeScript support
const mapStateToProps = (state: any) => ({
  stripeCustomerID: state.opening?.currentOrg?.customer?.id,
  currentOrg: state.opening.currentOrg,
  members: state.opening.members,
  loginUser: state.opening.loginUser,
  applicationId: state.opening.applicationId,
});

export default connect(mapStateToProps)(PricingTable);
