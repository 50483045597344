import axios, { AxiosError, AxiosInstance } from "axios";
import { toast } from "react-toastify";
import throwError, { throwSuccess } from "../service/throwError";
import {
  OpeningRes,
  getOpeningRes,
  createOpeningLabelRes,
  createOpeningMembershipRes,
  getOpeningListsRes,
  ApplicantRes,
  ListRes,
  getListByListIDsRes,
  ApplicantLabelRes,
  getOpeningMembersRes,
  getBoardLabelRes,
  getApplicantCommentsRes,
  CommentRes,
  ApplicantMembershipRes,
  getArchivedApplicantsRes,
  getCurrentUsageByCustomer,
  createCustomerRes,
  getServiceWiseUsageRes,
  invoice,
  updateBillingCustomerRes,
  getCouponRes,
  applyCouponRes,
  getAppliedCouponsRes,
  createSetupIntentRes,
  getDefaultPaymentMethodRes,
  getPaymentMethodListRes,
  getCustomerRes,
  updateDefaultPaymentMethodRes,
  getArchivedListsRes,
  getApplicantAttachmentsRes,
  AddAttachmentRes,
  AddAttachment,
  updateApplicantAttachmentRes,
  registrationRes,
  Register,
  JWTtokenExchangeRes,
  getOrgMemberRes,
  uploadAvtarRes,
  userData,
  updateUserInformationRes,
  getOrgDetailsRes,
  SlackOptionsRes,
  // SearchDocument,
  searchDocumentRes,
  ActivityRes,
  GetOrganisation,
  generateUserNameRes,
  isEmailExistRes,
  inviteMemberRes,
  copyAttachmentsRes,
  getFreeTierQuotaRes,
 
  changeBoardVisibilityRes,
  
  getReviewCandidateRes,
  Table,
  getTableRes,
  
  AddCustomColumnRes,
  addLabelToCDBRes,
  getCandidatesRes,
  createReviewRes,
  getCandidateRes,
  getSingleReviewCandidateRes,
  getReviewCommentsRes,
  createReviewCommentRes,
  attachLabelToCandidateRes,
  candidateLabel,
  assignMemberToReviewRes,
  AssignMemberToReview,
  attachLabelToReviewRes,
  updateCandidateAttachmentRes,
  getOpaBillingMembersRes,
  BillingMember,
  editCommentOfReviewRes,
  bulkLabel,
  addLabelToBulkReviewRes,
  bulkMember,
  addMembersToBulkReviewRes,
  uploadAttachmentOfCandidateRes,
  getCandidateApplicationRes,
  deleteLabelFromReviewRes,
  reviewsLabel,
  deleteLabelFromCandidateRes,
  attachBulkLabelsToCandidatesRes,
  PaginationReview,
  PaginationCandidates,
  getReviewsOfSelectedCandidate,
  
  sendMailres,
  paginationMail,
  getUserInboxRes,
  getThreadOfMessageRes,
  markMailAsReadRes,
  addCustomDomainRes,
  verifyDomainRes,
  UserRequirementFeedbackFormRes,
  sentOtpToUserPhoneRes,
  getMappingRes,
  getAllNotificationsRes,
  getUnreadNotificationsRes,
  addWatcherRes,
  updateUserSettingRes,
  getAllMappingsRes,
  updateMappingRes,
  getDashboardNotificationRes,
  getEntityNotificationRes,
  getWatcherRes,
  allNotifications,
  PaginationActivity,
  ActivityPaginationRes,
  getPhotosByUnsplashRes,
  Stage,
  ImportReport,
  
  Dashboardanalytic,
  getDashboardAnalytics,
  BoardStatistics,
  Plan,
  getRTURes,
  Notifications,
  lastVisitedRes,
  getWatcherOfModelRes,
  lastVisited,
  ReactEChartsOptions,
  LayoutsOptions,
  LayoutData,
  initOrganisationSetupRes,
  createOrgRes,
  createCheckoutSessionRes,
  CheckoutSession,
  newOrganisation,
  SetupStage,
  getStageRes,
  getPlansRes,
  getOrgAppIntegrationRes,
  Integration,
  getIntegratedAppMemberRes,
  Members,
  getBillingActivePlan,
  Billings,
  getAnalyticsLabelsType,
  UserIntegration,
  candidatePoolLabel,
  getAnalyticsLabelsDataType,
  gerIntegratedSlackMembers,
  SourcesResponse,
  Source,
  getSlackChannelsRes,
  SlackChannels,
  fetchEventsForCalendarRes,
  fetchEventsForCalendarResRoot,
  CalendarListResponseRoot,
  CalendarListResponse,
  CalendarColorsResponse,
  CalendarColorsResponseRoot,
  createCalendarRes,
  Calendars,
  getCalendarMemberResRoot,
  CalendarMembers,
  addCalendarMemberResRoot,
  calendarMemberItem,
  getUserCalendarPreferencesResRoot,
  userCalendarPreference,
  createGoogleMeetLinkRes,
  Spaces,
  getMeeting,
  getAllMeeting,
  getPromptTemplates,
  FeedbackRes,
  getFeedbackRes,
} from "./apiRes";
import {
  atsUploadHeader,
  authHeader1,
  uploadHeader,
  authHeader,
} from "../service/authHeaders";
import {
  Applicant,
  comment,
  Label,
  List,
  Member,
  Opening,
  SlackOptions,
  OutgoingInvitations,
  Candidate,
  Attachment,
  CandidateDetailReview,
  CustomColumn,
  CandidateShortReview,
  ReviewComment,
  paginationDetails,
  SendMail,
  RecordThreads,
  Mail,
  Domain,
  activity,
  ImageData,
  FormBuilder,
  Jd,
  company,
  User,
  IntegratedMember,
  NotificationSettingsPayload,
  UserNotificationSettings,
  Meetings,
  PromptTemplates,
  JobBoardSummary,
  JDDataResponse,
  feedback,
  loginUser,
} from "../type/type";
import {
  labelReq,
  updatedOpeningReq,
  AddMemberReq,
  updateBillingCustomerReq,
  applyCouponReq,
  createCustomerReq,
  createCustomerReq1,
 
  AddCustomColumnReq,
  AddLabelToCDBAndReviewReq,
  AddCommentOnReviewReq,
  AssignMemberToReviewReq,
  AddMemberToBillingReq,
  EditCommentOfReviewReq,
  addLabelsToBulkReviewReq,
  deleteLabelFromCandidateReq,
  sendMailReq,
  UpdateMemberRoleToBillingReq,
  UserRequirementFeedbackFormReq,
  sentOtpToUserPhoneReq,
  checkOtpToUserPhoneReq,
  addWatcherReq,
  addCustomDomainReq,
  attachLabelToCandidateReq,
  createReviewReq,
  
  createCheckoutSessionReq,
  addMembersToBulkReviewReq,
  ScheduleEventRequest,
  addCalendarMembersReq,
  EditFeedbackReq,
  AddFeedbackReq, 
} from "./apiReq";
import { SOCKET_CLIENT_ID } from "../constant";
import { FormTemplate } from "../components/JdManagement/FormTemplate/JobDescriptionForm"
import { OrgInfo } from "../type/type";
import { Integrations } from "../components/JdManagement/Types"
const epBoard = "/v1/ats/openings/";
const epList = "/v1/ats/lists/";
const epLabels = "/v1/ats/labels/";
const epMembers = "/v1/ats/members/";
const epApplicant = "/v1/ats/applicants";
const epComments = "/v1/ats/comments";
const epAttachment = "/v1/ats/attachments/";
const epFusion = "/v1/orgs/auth/user/";
const epNotification = "/v1/notifications/";
const epBackground = "/v1/ats/images"
const epDbmanager = "/v1/dbmanager/";
const epActivity = "/v1/activity/";
const epSearch = "/v1/search/documents/";
const epBilling = "/v1/billing/"
const epOpa = "/v1/ats/resource/";
const epCDB = "/v1/db/";
const epMail = "/v1/mail/";
const epJD = "/v1/jd/";
const epCompanies = "/v1/ats/companies"
const epAnalytics = "/v1/analytics/";
const epGPT = "/v1/gpts"
const epPublicService = "/v1/public/jobs/"
const epDepartments ="/v1/public/jobs/departments/"
const epScheduling="/v1/calendar/"

const epRTU="/v1/rtu";
const epATSRTU="/v1/ats/rtu";
const epCommunicationIntegration ="v1/chats"
const epESign = "/v1/esign/"
let api: AxiosInstance;

export const configureAPI = (baseURL: string | undefined) => {
  api = axios.create({ baseURL: baseURL });
};
/**************************************** Ats api *********************************************************/
//openings
export function getOpenings(archive?: boolean){
  const ep = `/v1/ats/openings?archived=${archive?archive:false}`;
  return new Promise<Opening[]>((resolve, reject) => {
    api
      .get<getOpeningRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.openings);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
}

//create board

export const createOpenings = (newOpening: any): Promise<Opening> => {
  let startTime = performance.now()
  return new Promise<Opening>((resolve, reject) => {
    api
      .post<OpeningRes>(epBoard, newOpening, { headers: authHeader() })
      .then((res) => {
        let endTime = performance.now() // End timing when response arrives
        let elapsedTime = endTime - startTime;
        if (elapsedTime < 1500){
          setTimeout(() => {
            resolve(res.data.opening);// Resolve after 500 milliseconds
          }, 1500 - elapsedTime)
        }else{
          if (res.status == 200 || res.status == 201) {
            resolve(res.data.opening);
          }
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getBoardByid = (id: string) => {
  const ep = epBoard + id;
  return new Promise<Opening>((resolve, reject) => {
    api
      .get<OpeningRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200 && res.data.opening) {
          resolve(res.data.opening);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const updateOpening = (updatedOpening: updatedOpeningReq) => {
  const ep = epBoard + "update/" + updatedOpening.id;
  return new Promise<Opening>((resolve, reject) => {
    api
      .put<OpeningRes>(ep, updatedOpening, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200 && res.data.opening) {
          resolve(res.data.opening);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const setOpeningBackground = (req: any) => {
  const ep = epBoard + `background/` + req.id;
  return new Promise<Opening>((resolve, reject) => {
    api
      .put<OpeningRes>(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200 && res.data.opening) {
          resolve(res.data.opening);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getPhotosByUnsplash = (query: string = "") => {
  // Trim the query and set default to "abstract" if it's empty or only whitespace
  const searchQuery = query.trim() !== "" ? query.trim() : "abstract"
  const ep = `${epBackground}?page=1&per_page=25&query=${searchQuery}&w=${window.innerWidth}&h=${window.innerHeight}`;
  return new Promise<ImageData[]>((resolve, reject) => {
    api
      .get<getPhotosByUnsplashRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200 && res.data.images) {
          resolve(res.data.images);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
};

export const triggerUnsplashDownloadLink = (url: string = "") => {
  const ep = `${url}&client_id=${process.env.REACT_APP_UNSPLASH}`
  return new Promise<boolean>((resolve, reject) => {
    api
      .get(ep)
      .then((res) => {
        if (res.status == 200) {
          resolve(true)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
export const starredBoard = (req: any) => {
  const ep = `${epBoard}starred/${req.id}`;
  return new Promise((resolve, reject) => {
    api
      .put(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200 && res.data.opening) {
          resolve(res.data.opening);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const archiveBoard = (id: string,archive:boolean,job_id?:string) => {
  const ep = `${epBoard}archive`;
  const req = {
    id: id,
    archive: archive?archive:false,
    ...(job_id && { job_id: job_id }),
  }
  return new Promise((resolve, reject) => {
    api
      .put(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const changeBoardVisibility = (req: any) => {
  const ep = `${epOpa}accessibility/transfer`;
  return new Promise((resolve, reject) => {
    api
      .put<changeBoardVisibilityRes>(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200 && res.data.accessibility) {
          resolve(res.data.accessibility);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const createOpeningLabel = (label: labelReq) => {
  const ep = epLabels + label.opening_id;
  return new Promise<Label>((resolve, reject) => {
    api
      .post<createOpeningLabelRes>(ep, label, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200 && res.data.label) {
          resolve(res.data.label);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const createOpeningMembership = (req: AddMemberReq) => {
  const ep = epMembers + "openingMembership/" + req.id;
  return new Promise<Member[]>((resolve, reject) => {
    api
      .post<createOpeningMembershipRes>(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200 && res.data.members) {
          resolve(res.data.members);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const removeOpeningMembership = (req: any) => {
  const ep = epMembers + "openingMembership/" + req.opening_id;
  return api.delete(ep, { headers: authHeader(), data: req });
};

//list
export const createList = (newList: any) => {
  const ep = epList + newList.opening_id;
  return new Promise<List>((resolve, reject) => {
    api
      .post<ListRes>(ep, newList, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200 && res.data.list) {
          res.data.list.applicants = [];
          resolve(res.data.list);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const getListByListIDs = (openingID: string) => {
  const ep = epList + openingID;
  return new Promise<List[]>((resolve, reject) => {
    api
      .get<getListByListIDsRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.lists_with_applicants);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const getOpeningLists = (openingID: string) => {
  const ep = epList + `ids/${openingID}`;

  return new Promise<List[]>((resolve, reject) => {
    api
      .get<getOpeningListsRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.lists);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const editListName = (req: any) => {
  const ep = epList + "name/" + req.id;
  return new Promise<List>((resolve, reject) => {
    api
      .put<ListRes>(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.list);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const archieveUnArchiveList = (req: any) => {
  const ep = epList + `archiveUnarchive/` + req.id;
  return new Promise<List>((resolve, reject) => {
    api
      .put<ListRes>(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.list);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//applicant
export const createApplicant = (newApplicant: any) => {
  const ep = epApplicant + "/" + newApplicant.list_id;
  return new Promise<Applicant>((resolve, reject) => {
    api
      .post<ApplicantRes>(ep, newApplicant, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          res.data.applicant.isFilter = true;
          resolve(res.data.applicant);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const getApplicant = (applicantID: string) => {
  const ep = epApplicant + `/${applicantID}`;
  return new Promise<Applicant>((resolve, reject) => {
    api
      .get<ApplicantRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          const applicant: Applicant = res.data.applicant;
          resolve(applicant);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const editApplicantDescription = (desc: any) => {
  const ep = epApplicant + "/description/" + desc.id;
  return new Promise<Applicant>((resolve, reject) => {
    api
      .put<ApplicantRes>(ep, desc, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.applicant);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const editApplicantName = (req: any) => {
  const ep = epApplicant + "/name/" + req.id;

  return new Promise<Applicant>((resolve, reject) => {
    api
      .put<ApplicantRes>(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.applicant);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const createApplicantLabel = (label: any) => {
  const ep = epLabels + "applicant/" + label.applicant_id;
  return new Promise<Label>((resolve, reject) => {
    api
      .post<ApplicantLabelRes>(ep, label, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.applicant_label);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getOpeningMembers = (openingID: string, applicationId: string) => {
  const ep = `${epMembers}${openingID}/${applicationId}/openingMembers`;
  return new Promise<Member[]>((resolve, reject) => {
    api
      .get<getOpeningMembersRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          res.data.members = res.data.members ? res.data.members : [];
          resolve(res.data.members);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getBoardLabel = (openingID: any) => {
  const ep = epLabels + "opening" + "/" + openingID + "/";
  return new Promise<Label[]>((resolve, reject) => {
    api
      .get<getBoardLabelRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.labels);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getApplicantComments = (applicantID: string) => {
  const ep = epComments + `/${applicantID}/applicant`;
  return new Promise<comment[]>((resolve, reject) => {
    api
      .get<getApplicantCommentsRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.comments);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const createComment = (newComment: any) => {
  const ep = epComments + "/" + newComment.applicant_id;
  return new Promise<comment>((resolve, reject) => {
    api
      .post<CommentRes>(ep, newComment, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.comment);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const editComment = (req: any, applicantId: string) => {
  const ep = epComments + "/" + applicantId;
  return new Promise<comment>((resolve, reject) => {
    api
      .put<CommentRes>(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.comment);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const moveApplicant = (req: any) => {
  const ep = epApplicant + "/move/" + req.src_applicant.id;
  return new Promise((resolve, reject) => {
    api
      .put(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.applicant);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const editLabel = (req: any) => {
  const ep = epLabels + "applicant/" + req.applicant_id;
  return new Promise<Label>((resolve, reject) => {
    api
      .put<ApplicantLabelRes>(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.applicant_label);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const createApplicantMembership = (req: any) => {
  const ep = epMembers + "applicantMembership/" + req.id;
  return new Promise<Member>((resolve, reject) => {
    api
      .post<ApplicantMembershipRes>(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.applicant_members);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const removeApplicantMembership = (req: any) => {
  const ep = epMembers + "applicantMembership/" + req.applicant_id;
  return new Promise<boolean>((resolve, reject) => {
    api
      .delete<ApplicantMembershipRes>(ep, {
        headers: authHeader(),
        data: req,
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(true);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const archieveUnArchiveApplicant = (req: any) => {
  const ep = epApplicant + `/archiveUnarchive/` + req.id;
  return new Promise<Applicant>((resolve, reject) => {
    api
      .put<ApplicantRes>(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.applicant);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getArchivedApplicants = (openingID: string) => {
  const ep = epApplicant + `/${openingID}/archived`;
  return new Promise<Applicant[]>((resolve, reject) => {
    api
      .get<getArchivedApplicantsRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.applicants);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getArchivedLists = (openingID: string) => {
  const ep = epList + `archiveLists/${openingID}`;
  return new Promise<List[]>((resolve, reject) => {
    api
      .get<getArchivedListsRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.archived_list);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
//get attachment
export const getApplicantAttachments = (applicantID: string) => {
  const ep = epAttachment + `applicants/${applicantID}`;
  return new Promise<Attachment[]>((resolve, reject) => {
    api
      .get<getApplicantAttachmentsRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.attachments);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const moveList = (req: any) => {
  const ep = epList + "move/" + req.id;
  return new Promise((resolve, reject) => {
    api
      .put(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.move_list);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const moveListAplicants = (req: any) => {
  const ep = epList + "moveListApplicants/" + req.id;
  return new Promise((resolve, reject) => {
    api
      .put(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.move_list_applicants);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const dragApplicant = (dragedApplicant: any) => {
  const ep = epApplicant + "/move/" + dragedApplicant.src_applicant.id;
  return api.put(ep, dragedApplicant, { headers: authHeader() });
};

export const deleteLabel = (req: any) => {
  const ep = epLabels + "applicant/";
  return api.delete(ep, { headers: authHeader(), data: req });
};

export const archivedAll = (req: any) => {
  const ep = epList + "archiveAllApplicants/" + req.id;
  return new Promise((resolve, reject) => {
    api
      .put(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.list);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const copyApplicant = (req: any) => {
  const ep = epApplicant + "/copy/" + req.src_applicant.id;
  return new Promise((resolve, reject) => {
    api
      .post(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.copy_applicant);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//upload attachment
export const addAttachment = (
  formData: any,
  id: string,
  onUploadProgress?: any,
  onDownloadProgress?: any
) => {
  const ep = epAttachment + id;
  return new Promise<AddAttachment>((resolve, reject) => {
    api
      .post<AddAttachmentRes>(ep, formData, {
        headers: atsUploadHeader(),
        onUploadProgress,
        onDownloadProgress,
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.attachment);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const copyAttachments = (ats: { [key: string]: Attachment[] }) => {
  const ep = epAttachment + "copy";
  return new Promise<Attachment[]>((resolve, reject) => {
    api
      .post<copyAttachmentsRes>(ep, ats, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.attachment);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const deleteApplicantAttachment = (req: any) => {
  const ep = epAttachment;
  return new Promise((resolve, reject) => {
    api
      .delete(ep, { headers: authHeader(), data: req })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.deleted_attachment);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const updateApplicantAttachment = (attachment: any) => {
  const ep = epAttachment + attachment.id;
  return new Promise<Attachment>((resolve, reject) => {
    api
      .put<updateApplicantAttachmentRes>(ep, attachment, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.attachment);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const setTokenInLocalStorage = (
  tokenIdentifire: string,
  token: string
) => {
  return new Promise<boolean>((resolve, reject) => {
    try {
      localStorage.setItem(tokenIdentifire, token);
      resolve(true);     
    } catch {
      reject(false);
    }
  });
};

export const setTokenInSessionStorage = (token: string) => {
  return new Promise<boolean>((resolve, reject) => {
    try {
      sessionStorage.setItem("current_tab_application_token", token);
      resolve(true);
    } catch {
      reject(false);
    }
  });
};
export const setAnyTokenInSessionStorage = (key: string,value:string) => {
  return new Promise<boolean>((resolve, reject) => {
    try {
      sessionStorage.setItem(key, value);
      resolve(true);
    } catch {
      reject(false);
    }
  });
};


/********************************* fusion auth  ******************************/


// get user 
export const getUser = (user_id: string) => {
  const ep = epFusion + `retrive/${user_id}`;
  return new Promise<User>((resolve, reject) => {
    api
      .get<registrationRes>(ep, { headers: authHeader1() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.user.user);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
//update invitation status
export const updateInvitationStatusApi = (req: any) => {
  const ep = epFusion + "invitation/status";
  return new Promise<OutgoingInvitations>((resolve, reject) => {
    api
      .put<inviteMemberRes>(ep, req, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.invitation);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
//sign up
export const registration = (regObject: any,InvitationSecret?:string) => {
  const ep = InvitationSecret? epFusion + `create?invitation_secret=${InvitationSecret}`:epFusion + `create`; 
  console.log(InvitationSecret)
  return new Promise<Register>((resolve, reject) => {
    api
      .post<registrationRes>(ep, regObject)
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.user);
        }
      })
      .catch((reason: AxiosError) => {
        reject(reason);
      });
  });
};


//login
export const login = (loginObject: any) => {
  const ep = epFusion + "login";
  return new Promise<Register>((resolve, reject) => {
    api
      .post<registrationRes>(ep, loginObject)
      .then((res) => {
        if (res.status == 200 || res.status == 212) {
          resolve(res.data.user);
        }
      })
      .catch((reason: AxiosError) => {
        reject(reason);
      });
  });
};

//inviteMember
export const inviteMember = (inviteObj: any) => {
  const ep = epFusion + "invite";
  return new Promise<OutgoingInvitations[]>((resolve, reject) => {
    api
      .post<inviteMemberRes>(ep, inviteObj, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.invitation);
        }
      })
      .catch((reason: AxiosError) => {
        reject(reason);
      });
  });
};

//resendResetPasswordLink
export const resendResetPasswordLink = (forgotPwdObject: any) => {
  const ep = epFusion + `forgot/password`;
  return new Promise((resolve, reject) => {
    api
      .post(ep, forgotPwdObject)
      .then((res: any) => {
        if (res.status == 200) {
          resolve(true);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const lunchInvitedOrg = (userId: string, reqObj: any) => {
  const ep = epFusion + `register/${userId}`;
  return new Promise((resolve, reject) => {
    api
      .post(ep, reqObj, { headers: authHeader1() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.registration);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//getInvitations
export const getInvitations = (email: string) => {
  const ep = epFusion + `invitations/incoming/${email}`;
  return new Promise((resolve, reject) => {
    api
      .get(ep, { headers: authHeader1() })
      .then((res: any) => {
        if (res.status == 200) {
          res.data.invitations = res.data.invitations
            ? res.data.invitations
            : [];
          resolve(res.data.invitations);
        }
      })
      .catch((reason: AxiosError) => {
        // toast.error(reason.response?.data.error.type, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        reject(reason);
      });
  });
};
//get outgoing invitation
export const getOutgoingInvitations = (
  userId: string,
  applicationId: string
) => {
  const ep = epFusion + `invitations/outgoing/${userId}/${applicationId}`;
  return new Promise<OutgoingInvitations>((resolve, reject) => {
    api
      .get<inviteMemberRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.invitations);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
//check email exist
export const isEmailExist = (email: string) => {
  const ep = epFusion + `check/${email}`;
  return new Promise<boolean>((resolve, reject) => {
    api
      .get<isEmailExistRes>(ep)
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.user.exists);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//token exchange
export const JWTtokenExchange = (orgId: string, loginUserId: string) => {
  const ep = epFusion + `jwt/exchange/${orgId}/${loginUserId}`;
  return new Promise<string>((resolve, reject) => {
    api
      .get<JWTtokenExchangeRes>(ep, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.application_based_token.token);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//getOrg member
export const getOrgMember = (orgId: string) => {
  const ep = epFusion + `${orgId}`;
  return new Promise<Member[]>((resolve, reject) => {
    api
      .get<getOrgMemberRes>(ep, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.user);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//getSlackIntegratedMembers
export const getIntegratedSlackMember = () => {
  const ep = epCommunicationIntegration + "/members/all"
  return new Promise<IntegratedMember[]>((resolve, reject) => {
    api
      .get<gerIntegratedSlackMembers>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.integration_members)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
//connectSlackOfSingleUser
export const connectSlackOfSingleUser = (req: any) => {
  const ep = epCommunicationIntegration + `/auth/registerusers?client=slack`
  let startTime = performance.now() // Start timing

  return new Promise<IntegratedMember[]>((resolve, reject) => {
    api
      .post<gerIntegratedSlackMembers>(ep, req, { headers: authHeader() })
      .then((res) => {
        let endTime = performance.now() // End timing when response arrives
        let elapsedTime = endTime - startTime // Calculate elapsed time
        if (elapsedTime < 1500) {
          // If response arrives within 300 milliseconds
          setTimeout(() => {
            resolve(res.data.integration_members) // Resolve after 500 milliseconds
          }, 1500 - elapsedTime)
        } else {
          if (res.status == 200 || res.status == 201) {
            resolve(res.data.integration_members)
          }
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

//remove slack user
export const removeIndividualSlackUser = (user_id: string) => {
  const ep = epCommunicationIntegration + `/auth/user/registration?client=slack&user_id=${user_id}`
  let startTime = performance.now() // Start timing

  return new Promise<boolean>((resolve, reject) => {
    api
      .delete<{ deleted: boolean }>(ep, { headers: authHeader() })
      .then((res) => {
        let endTime = performance.now() // End timing when response arrives
        let elapsedTime = endTime - startTime // Calculate elapsed time
        if (elapsedTime < 1500) {
          // If response arrives within 300 milliseconds
          setTimeout(() => {
            resolve(res.data.deleted)
          }, 1500 - elapsedTime)
        } else {
          if (res.status == 200 || res.status == 201) {
            resolve(res.data.deleted)
          }
        }

      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
};
//upload avtar
export const uploadAvtar = (userId: any, formData: any) => {
  const ep = epFusion + `upload/avtar/${userId}`;
  return new Promise<userData>((resolve, reject) => {
    api
      .post<uploadAvtarRes>(ep, formData, { headers: uploadHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.user);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
//update user info
export const updateUserInformation = (userId: any, formData: any) => {
  const ep = epFusion + `update/${userId}`;
  return new Promise<userData>((resolve, reject) => {
    api
      .patch<updateUserInformationRes>(ep, formData, {
        headers: uploadHeader(),
      })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.user.user);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//email verification
export const checkEmailVerify = (verificationId: string) => {
  const ep = epFusion + `verify-email/${verificationId}`;
  return new Promise((resolve, reject) => {
    api
      .get(ep)
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//resend email
export const resendEmailVerification = (email: string) => {
  const ep = epFusion + `resend/verification/${email}`;
  return new Promise((resolve, reject) => {
    api
      .get(ep, { headers: authHeader1() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
//forget password

export const forgetPassword = (forgotPwdObject: any) => {
  const ep = epFusion + `forgot/password`;
  return new Promise((resolve, reject) => {
    api
      .post(ep, forgotPwdObject)
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//reser password
export const resetPassword = (resetPwdObject: any) => {
  const ep = epFusion + `change/password`;
  return new Promise((resolve, reject) => {
    api
      .post(ep, resetPwdObject)
      .then((res) => {
        if (res.status == 200) {
          resolve(true);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//checkUsernameExist
export const checkUsernameExist = (username: string) => {
  const ep = epFusion + `username/check?name=${username}`;
  return new Promise<boolean>((resolve, reject) => {
    api
      .get<{user_name:{exists:boolean}}>(ep, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.user_name.exists);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//fetchUserName
export const generateUserName = (email: string) => {
  const ep = epFusion + `username/?name=${email}`;
  return new Promise<string>((resolve, reject) => {
    api
      .get<generateUserNameRes>(ep, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.user_name);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getRoles = (id: string) => {
  const ep = epFusion + `roles/${id}`
  return new Promise<any>((resolve, reject) => {
    api
      .get<any>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.application_roles)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

export const checkForgetPasswordLinkExpiration = (id: string) => {
  const ep = epFusion + `check/passwordid/${id}`
  return new Promise<boolean>((resolve, reject) => {
    api
      .get<any>(ep)
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.change_password.valid);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
/************************************* DB manager apis ****************************/

//create org
export const createOrganisation = (orgReq: any) => {
  const ep = epDbmanager + `orgs/`;
  return new Promise<newOrganisation>((resolve, reject) => {
    api
      .post<createOrgRes>(ep, orgReq, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.organisation);
        }
      })
      .catch((reason: AxiosError) => {
        // throwError(reason)
        reject(reason);
      });
  });
};
//get Stages
export const getStages = (org_id:string) => {
  const ep = epDbmanager + `orgs/setup/stages?org_id=${org_id}`;
  return new Promise<SetupStage[]>((resolve, reject) => {
    api
      .get<getStageRes>(ep,  { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.setup_stages);
        }
      })
      .catch((reason: AxiosError) => {
        // throwError(reason)
        reject(reason);
      });
  });
};
// createCheckoutSession
export const createCheckoutSession = (checkoutSessionReq: createCheckoutSessionReq) => {
  const ep = epDbmanager + `billing/subscriptions/session`;
  return new Promise<CheckoutSession>((resolve, reject) => {
    api
      .post<createCheckoutSessionRes>(ep, checkoutSessionReq, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.checkout_session);
        }
      })
      .catch((reason: AxiosError) => {
        // throwError(reason)
        reject(reason);
      });
  });
};

export const freePlanApi = (cust_id:string)=>{
  const ep = epDbmanager + `billing/set-up/free?cust_id=${cust_id}`
  return new Promise<any>((resolve, reject) => {
    api
      .post<any>(ep,null, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        reject(reason);
      });
  })
}

export const downGradePlan = ()=>{
  const ep = epDbmanager + `billing/subscriptions`
  return new Promise<any>((resolve, reject) => {
    api
      .delete<any>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        reject(reason);
      });
  })
}
// create department 

export const getDepartment = (name:string) => {
  const ep = epDepartments + `${name}`;
  return new Promise<{ "department": [], "job_type": [], "location": [] }>((resolve, reject) => {
    api
      .get<any>(ep,{ headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.jd_data);
        }
      })
      .catch((reason: AxiosError) => {
        // throwError(reason)
        reject(reason);
      });
  });
};



// create org setup
export const initOrganisationSetup = (orgReq: any) => {
  const ep = epDbmanager + `orgs/initsetup`;
  return new Promise<newOrganisation>((resolve, reject) => {
    api
      .post<initOrganisationSetupRes>(ep, orgReq, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.initialized_organisations);
        } 
      })
      .catch((reason: AxiosError) => {
        // throwError(reason)
        reject(reason);
      });
  });
};


export const getOrgDetails = (orgId: any) => {
  const ep = epDbmanager + `orgs/${orgId}`;
  return new Promise<newOrganisation>((resolve, reject) => {
    api
      .get<createOrgRes>(ep, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.organisation);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//upload org logo
export const uploadOrgLogo = (orgId: any, formData: any) => {
  const ep = epDbmanager + `orgs/${orgId}`;
  return new Promise<GetOrganisation>((resolve, reject) => {
    api
      .patch<getOrgDetailsRes>(ep, formData, { headers: atsUploadHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.organisation);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const updateDescription = (orgId: string, descriptionData: any)=> {
  const ep = epDbmanager + `orgs/${orgId}`;
  return new Promise<any>((resolve, reject) => {
    api
      .put<OrgInfo>(ep, descriptionData, { headers: authHeader()})
      .then((res) => {
        if (res.status === 200) {
          resolve(res);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

/*********************************************** Acitity **********************************************************/
export const getActivityByPagination = (modelName: string, modelId: any, page?: string, per_page?: string) => {
  let ep: string;

    ep =
    epActivity + `${modelName}/${modelId}/?page=${page}&per_page=${per_page}`;
    return new Promise<PaginationActivity & paginationDetails>(
      (resolve, reject) => {
        api
          .get<ActivityPaginationRes>(ep, { headers: authHeader() })
          .then((res) => {
            if (res.status == 200) {
              resolve(res.data.activities);
            }
          })
          .catch((reason: AxiosError) => {
            throwError(reason);
            reject(reason);
          });
      }
    );
};
export const getActivity = (modelName: string, modelId: any) => {
  const ep = epActivity + `${modelName}/${modelId}`;
  return new Promise<activity[]>((resolve, reject) => {
    api
      .get<ActivityRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          res.data.activities = res.data.activities ? res.data.activities : [];
          resolve(res.data.activities);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason);
      });
  });
};
/*************************************** notification **************************************************************/

// var URL="http://192.168.68.119:8001/v1/notifications/"

// getDefaultNotificationSettingForm
export const getDefaultNotificationSettingForm = () => {
  const ep = epNotification + `user_setting`;

  return new Promise<updateUserSettingRes>((resolve, reject) => {
    api
      .get<updateUserSettingRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// getNotificationSetting
export const getNotificationSetting = (user_id: string) => {
  const ep = epNotification + `user_setting/${user_id}`;

  return new Promise<updateUserSettingRes>((resolve, reject) => {
    api
      .get<updateUserSettingRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// updateUserNotificationSetting
export const updateUserNotificationSetting = (req: any, user_id: string) => {
  const ep = epNotification + `user_setting/${user_id}`;

  return new Promise<updateUserSettingRes>((resolve, reject) => {
    api
      .put<updateUserSettingRes>(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
// addUserNotificationSetting
export const addUserNotificationSetting = (req: any) => {
  const ep = epNotification + `user_setting`;

  return new Promise<updateUserSettingRes>((resolve, reject) => {
    api
      .post<updateUserSettingRes>(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
// readNotificationOfEntity
export const readNotificationOfEntity = (Req: allNotifications[]) => {
  const ep = epNotification + `messages/read`;
  return new Promise<addWatcherRes>((resolve, reject) => {
    api
      .post<addWatcherRes>(ep, Req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// getEntityUnreadNotifications
export const getEntityUnreadNotifications = (
  user_id: string,
  entity_id: string,entity_name:string
) => {
  const ep = epNotification + `${user_id}/${entity_name}/${entity_id}`;
  return new Promise<getEntityNotificationRes>((resolve, reject) => {
    api
      .get<getEntityNotificationRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// getDashboardNotifications
export const getDashboardNotifications = (entity_id: string) => {
  const ep = epNotification + `dashboard/${entity_id}`;
  return new Promise<getDashboardNotificationRes>((resolve, reject) => {
    api
      .get<getDashboardNotificationRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
// getCardNotificationsDot
export const getCardNotificationsDot = (board_id: string) => {
  const ep = epNotification + `parent_entity/${board_id}`;
  return new Promise<getDashboardNotificationRes>((resolve, reject) => {
    api
      .get<getDashboardNotificationRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// getBoardNotificationsDot
export const getBoardNotificationsDot = (user_id: string,entity:string) => {
  const ep = epNotification + `${user_id}/${entity}`;
  return new Promise<getDashboardNotificationRes>((resolve, reject) => {
    api
      .get<getDashboardNotificationRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// getAllNotifications
export const getAllNotification = (userId: string) => {
  const ep = epNotification + `${userId}`;
  return new Promise<getAllNotificationsRes>((resolve, reject) => {
    api
      .get<getAllNotificationsRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
// getUnreadNotifications
export const getUnreadNotification = (userId: string) => {
  const ep = epNotification + `unread/${userId}`;
  return new Promise<getUnreadNotificationsRes>((resolve, reject) => {
    api
      .get<getUnreadNotificationsRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
// addWatcher
export const addWatcher = (Req: addWatcherReq) => {
  const ep = epNotification + `subscriptions`;
  return new Promise<addWatcherRes>((resolve, reject) => {
    api
      .post<addWatcherRes>(ep, Req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// addWatcher
export const removeWatcherOfEntity = (entity_id: string,entity_name:string,user_id:string) => {
  const ep = epNotification + `subscriptions/${entity_name}/${entity_id}/${user_id}`;
  return new Promise<addWatcherRes>((resolve, reject) => {
    api
      .delete(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// getWatcher
export const getWatcherOfEntity = (entity_id: string) => {
  const ep = epNotification + `subscriptions/${entity_id}`;
  return new Promise<getWatcherRes>((resolve, reject) => {
    api
      .get<getWatcherRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//getSlackOptions
export const getSlackOptions = () => {
  const ep = epNotification + `slack/options`;
  return new Promise<SlackOptions>((resolve, reject) => {
    api
      .get<SlackOptionsRes>(ep, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.slack);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
//get slack option of opening
export const getSlackOptionOfOpening = (boardId: any) => {
  const ep = epNotification + `slack/subscriber?topic=${boardId}`;
  return new Promise<SlackOptions>((resolve, reject) => {
    api
      .get<SlackOptionsRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.slack);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
//update slack option

export const UpdateSlackOptionOfOpening = (
  slackOptionId: any,
  slackReqObj: any
) => {
  const ep = epNotification + `slack/update/${slackOptionId}`;
  return new Promise<SlackOptions>((resolve, reject) => {
    api
      .patch<SlackOptionsRes>(ep, slackReqObj, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.slack);
        }
      })
      .catch((reason: AxiosError) => {
        toast.error(reason.response?.data.error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        reject(reason);
      });
  });
};

//subscribe to slack

export const integrateToSlack = (slackReqObj: any) => {
  const ep = epNotification + `slack/subscribe`;
  return new Promise<SlackOptions>((resolve, reject) => {
    api
      .post<SlackOptionsRes>(ep, slackReqObj, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.slack);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//deleteslack integration
export const deleteSlackIntegration = (id: string, clientId: string) => {
  const ep = epNotification + `slack/subscriber/${id}/${clientId}`;
  return new Promise((resolve, reject) => {
    api
      .delete(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

/********************************************************** Search   **************************************************************/
//search document

export const searchDocument = (searchRequest: any) => {
  const ep = epSearch + `find`;
  return new Promise<any>((resolve, reject) => {
    api
      .post<searchDocumentRes>(ep, searchRequest, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.search_documents)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

export const searchCandidateDocument = (searchRequest: any) => {
  const ep = epSearch + `find/candidates`
  return new Promise<any>((resolve, reject) => {
    api
      .post<any>(ep, searchRequest, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.candidates);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const searchCandidateReviewsDocument = (searchRequest: any) => {
  const ep = epSearch + `find/reviews`
  return new Promise<any>((resolve, reject) => {
    api
      .post<any>(ep, searchRequest, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.reviews);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const searchMailsDocument = (searchRequest: any) => {
  const ep = epSearch + `find/mails`
  return new Promise<any>((resolve, reject) => {
    api
      .post<any>(ep, searchRequest, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.mails);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};


//  Billing
export const getCurrentUsageOfCustomer = (external_id: string) => {
  const ep = epBilling + `customers/${external_id}/current_usage`;

  return new Promise<getCurrentUsageByCustomer>((resolve, reject) => {
    api
      .get<getCurrentUsageByCustomer>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// getPaymentMethodListOfCustomer
export const getPaymentMethodList = (external_id: string) => {
  const ep = epBilling + `customers/${external_id}/payment_details`;

  return new Promise<getPaymentMethodListRes>((resolve, reject) => {
    api
      .get<getPaymentMethodListRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// getCustomerServiceWiseUsage
export const getServiceWiseUsage = (external_id: string) => {
  const ep =
    epBilling + `customers/${external_id}/past_month_and_current_usage`;

  return new Promise<getServiceWiseUsageRes>((resolve, reject) => {
    api
      .get<getServiceWiseUsageRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// updateCustomer
export const updateCustomer = (
  req: updateBillingCustomerReq,
  external_id: string
) => {
  const ep = epBilling + `customers/${external_id}`;

  return new Promise<updateBillingCustomerRes>((resolve, reject) => {
    api
      .put<updateBillingCustomerRes>(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// getCustomer
export const getCustomer = (external_id: string) => {
  const ep = epBilling + `customers/${external_id}`;

  return new Promise<getCustomerRes>((resolve, reject) => {
    api
      .get<getCustomerRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// getCustomerInvoices
export const getInvoices = (external_id: string) => {
  const ep = epBilling + `customers/${external_id}/invoices`;

  return new Promise<invoice[]>((resolve, reject) => {
    api
      .get<invoice[]>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data.invoices);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// getOpaBillingMembers
export const getOpaBillingMembers = (org_id: string) => {
  const ep = epBilling + `opa/members/${org_id}`;

  return new Promise<getOpaBillingMembersRes>((resolve, reject) => {
    api
      .get<getOpaBillingMembersRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// addOpaBillingMembers
export const addOpaBillingMember = (req: AddMemberToBillingReq) => {
  const ep = epBilling + `opa/members`;

  return new Promise<BillingMember[]>((resolve, reject) => {
    api
      .post<BillingMember[]>(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data.members);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// removeMemberFromBilling
export const removeOpaBillingMember = (req: any) => {
  const ep = epBilling + `opa/members`;

  return new Promise<BillingMember>((resolve, reject) => {
    api
      .delete<BillingMember>(ep, { headers: authHeader(), data: req })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// updateMemberFromBilling
export const updateOpaBillingMember = (req: UpdateMemberRoleToBillingReq) => {
  const ep = epBilling + `opa/members`;

  return new Promise<BillingMember>((resolve, reject) => {
    api
      .patch<BillingMember>(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data.member);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// getCustomerCoupons

export const getCoupon = () => {
  const ep = epBilling + `coupons`;

  return new Promise<getCouponRes>((resolve, reject) => {
    api
      .get<getCouponRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// applyCoupon

export const applyCoupon = (req: applyCouponReq) => {
  const ep = epBilling + `coupons/apply`;

  return new Promise<applyCouponRes>((resolve, reject) => {
    api
      .post<applyCouponRes>(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// getAppliedCoupons

export const getAppliedCoupons = (external_id: string) => {
  const ep = epBilling + `coupons/customer/${external_id}`;

  return new Promise<getAppliedCouponsRes>((resolve, reject) => {
    api
      .get<getAppliedCouponsRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};
// setUpStatus

export const setUpStatus = (req: any,external_id:string) => {
  const ep = epDbmanager + `billing/setup/status?org_id=${external_id}`;

  return new Promise<createSetupIntentRes>((resolve, reject) => {
    api
      .put<createSetupIntentRes>(ep,req, { headers: authHeader1() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);          
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};
// setUpStatus
export const getCustomerBillingDashboard = (customer_id: string,external_id:string) => {
  const ep = epDbmanager + `billing/active/plan?cust_id=${customer_id}&org_id=${external_id}`;

  return new Promise<any>((resolve, reject) => {
    api
      .get<any>(ep, { headers: authHeader1() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
          
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};
// createSetupIntent

export const createSetupIntent = (external_id: string) => {
  const ep = epDbmanager + `billing/set_up_intent?org_id=${external_id}`;

  return new Promise<createSetupIntentRes>((resolve, reject) => {
    api
      .get<createSetupIntentRes>(ep, { headers: authHeader1() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);          
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};



// getPlans
export const getDefaultPlansOfOrg = () => {
  const ep = epDbmanager + `billing/plans`;
  return new Promise<Plan[]>((resolve, reject) => {
    api
      .get<getPlansRes>(ep, { headers: authHeader1() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data.plans);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason.response);
        }
      });
  });
};
// addPlan
export const addPlanToCustomer = (req:any) => {
  const ep = epDbmanager + `billing/subscriptions`;
  return new Promise<any>((resolve, reject) => {
    api
      .post<any>(ep,req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason.response);
        }
      });
  });
};

// updateDefaultPaymentMethod
export const updateBillingCustomer = (cus_id:string,req:any) => {
  const ep =
    epDbmanager +
    `billing/customers?cust_id=${cus_id}`;

  return new Promise<any>((resolve, reject) => {
    api
      .put<any>(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// getDefaultPaymentMethod

export const getDefaultPaymentMethod = (external_id: string) => {
  const ep = epBilling + `customers/${external_id}/payment_details/default`;
  return new Promise<getDefaultPaymentMethodRes>((resolve, reject) => {
    api
      .get<getDefaultPaymentMethodRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason.response);
        }
      });
  });
};

// createCustomer
export const createBillingCustomer = (
  req: createCustomerReq | createCustomerReq1
) => {
  const ep = epBilling + `customers/`;

  return new Promise<createCustomerRes>((resolve, reject) => {
    api
      .post<createCustomerRes>(ep, req, { headers: authHeader1() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// updateDefaultPaymentMethod
export const updateDefaultPaymentMethod = (pid: any, external_id: string) => {
  const ep =
    epBilling +
    `customers/${external_id}/payment_details?payment_method_id=${pid}`;

  return new Promise<updateDefaultPaymentMethodRes>((resolve, reject) => {
    api
      .put<updateDefaultPaymentMethodRes>(ep, {}, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};
// getFreeTier
export const getFreeTierQuota = (external_id: string) => {
  const ep = epBilling + `customers/${external_id}/free_tier_limit`;

  return new Promise<getFreeTierQuotaRes>((resolve, reject) => {
    api
      .get<getFreeTierQuotaRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// ***************************************************** calendar *************************************************************//
// incomplete
// getEvent
export const getEvent = (event_id: string) => {
  const ep = epBilling + `customers/${event_id}/free_tier_limit`;

  return new Promise<getFreeTierQuotaRes>((resolve, reject) => {
    api
      .get<getFreeTierQuotaRes>(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason);
        }
      });
  });
};

// UserRequirementFeedbackFormReq

export const UserRequirementFeedbackForm = (
  req: UserRequirementFeedbackFormReq
) => {
  const ep = epFusion + "feedback";
  return new Promise<UserRequirementFeedbackFormRes>((resolve, reject) => {
    api
      .post<UserRequirementFeedbackFormRes>(ep, req, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};



/*************************************** OPA  ************************************************************ */
export const editRoleOfBoardMember = (req: any) => {
  const ep = epOpa + "membership/access";
  return new Promise<Member>((resolve, reject) => {
    api
      .patch(ep, req, {
        headers: authHeader(),
      })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.member);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
// user mobile verification
// send otp
export const sendOtpToUserPhone = (req: sentOtpToUserPhoneReq) => {
  const ep = epFusion + "mobile/verification";
  return new Promise<sentOtpToUserPhoneRes>((resolve, reject) => {
    api
      .post<sentOtpToUserPhoneRes>(ep, req, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const activateDeactivateMember = (req: any) => {
  const ep = epFusion + "active/organisation";
  return new Promise((resolve, reject) => {
    api
      .put(ep, req, {
        headers: authHeader(),
      })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.user_active_organisation);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
/*************************** candidate DB  *********************************/
export const getCandidate = (cid:string) => {
  const ep = epCDB + `candidates/id?id=${cid}`;
  return new Promise<Candidate>((resolve, reject) => {
    api
      .get<getCandidateRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.candidates);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const createCustomColumn = (req: any) => {
  const ep = `${epCDB}membership/access`;
  return new Promise<Member>((resolve, reject) => {
    api
      .patch(ep, req, {
        headers: authHeader(),
      })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.member);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const getCustomColumn = (req: any) => {
  const ep = epCDB + "membership/access";
  return new Promise<Member>((resolve, reject) => {
    api
      .patch(ep, req, {
        headers: authHeader(),
      })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.member);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const createQuickCandidate = (medium_type:string,req: any) => {
  const ep = epCDB +`candidates/pub?medium_type=${medium_type}`;
  return new Promise<Candidate>((resolve, reject) => {
    api
      .post(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          resolve(res.data.candidates);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const editLabelOfCDB = (req: any) => {
  const ep = epCDB + "labels";
  return new Promise<Label>((resolve, reject) => {
    api
      .patch<addLabelToCDBRes>(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.labels);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const getTable = () => {
  const ep = epCDB + "tables?name=candidates";
  return new Promise<Table>((resolve, reject) => {
    api
      .get<getTableRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.tables);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//for formBuilder
export const getCDBTable = () => {
  const ep = epCDB + "tables/candidate?name=candidates"
  return new Promise<Table>((resolve, reject) => {
    api
      .get<getTableRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.tables)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
export const createReview = (req: createReviewReq) => {
  const ep = epCDB + "reviews";
  return new Promise<Candidate[]>((resolve, reject) => {
    api
      .post<createReviewRes>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          resolve(res.data.reviews);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const getReviewCandidate = (
  type: boolean,
  currentPage: number,
  pageSize: number
) => {
  const ep =
    epCDB + `reviews?reviewed=${type}&page=${currentPage}&per_page=${pageSize}`;
  return new Promise<PaginationReview & paginationDetails>(
    (resolve, reject) => {
      api
        .get<getReviewCandidateRes>(ep, {
          headers: authHeader(),
        })
        .then((res) => {
          if (res.status == 200) {
            resolve(res.data.reviews);
          }
        })
        .catch((reason: AxiosError) => {
          throwError(reason);
          reject(reason);
        });
    }
  );
};

export const getSingleReviewCandidate = (candidateID: string) => {
  const ep = epCDB + `reviews/id?id=${candidateID}`;
  return new Promise<CandidateDetailReview>((resolve, reject) => {
    api
      .get<getSingleReviewCandidateRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.reviews);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const createCandidateLabel = (type: boolean) => {
  const ep = epCDB + `review?id=${type}`;
  return new Promise((resolve, reject) => {
    api
      .post(ep, {
        headers: authHeader(),
      })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.candidates);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const showCandidateDBMember = () => {
  const ep = epCDB + `members`;
  return new Promise<Member[]>((resolve, reject) => {
    api
      .get(ep, {
        headers: authHeader(),
      })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.members);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// Members
export const addMemberToCDB = (req: AddMemberReq) => {
  const ep = epCDB + `members`;
  return new Promise<Member[]>((resolve, reject) => {
    api
      .post(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.members);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const addCustomColumn = (req: AddCustomColumnReq) => {
  const ep = epCDB + `custom/columns`;
  return new Promise<CustomColumn[]>((resolve, reject) => {
    api
      .post<AddCustomColumnRes>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          resolve(res.data.customes);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const addLabelToCDBAndReview = (req: AddLabelToCDBAndReviewReq) => {
  const ep = epCDB + `labels`;
  return new Promise<Label>((resolve, reject) => {
    api
      .post<addLabelToCDBRes>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.labels);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const deleteLabelFromReview = (req: addLabelsToBulkReviewReq) => {
  const ep = epCDB + `labels/review`;
  return new Promise<reviewsLabel>((resolve, reject) => {
    api
      .delete<deleteLabelFromReviewRes>(ep, {
        headers: authHeader(),
        data: req,
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.reviews_labels);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const deleteLabelFromCandidate = (req: deleteLabelFromCandidateReq) => {
  const ep = epCDB + `labels/candidate`;
  return new Promise<candidatePoolLabel>((resolve, reject) => {
    api
      .delete<deleteLabelFromCandidateRes>(ep, {
        headers: authHeader(),
        data: req,
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.candidate_labels);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getCandidates = (currentPage: number, pageSize: number,job_id?:string,tag?:string) => {
  const epUrl = epCDB + `candidates?page=${currentPage}&per_page=${pageSize}`;
  let ep = tag ? `${epUrl}&tag=${tag}&order=desc`:epUrl;
  if (job_id) {
    ep += `&job_id=${job_id}`;
  }  return new Promise<PaginationCandidates & paginationDetails>(
    (resolve, reject) => {
      api
        .get<getCandidatesRes>(ep, {
          headers: authHeader(),
        })
        .then((res) => {
          if (res.status == 200) resolve(res.data.candidates);
        })
        .catch((reason: AxiosError) => {
          throwError(reason);
          reject(reason);
        });
    }
  );
};

export const getLabels = (entity: string) => {
  const ep = epCDB + `labels?entity=${entity}`;
  return new Promise<Label[]>((resolve, reject) => {
    api
      .get<getBoardLabelRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.labels);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getCandidateLabel = (candidateID: string) => {
  const ep = epCDB + `labels/candidate?id=${candidateID}`;
  return new Promise<Label[]>((resolve, reject) => {
    api
      .get<getBoardLabelRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.labels);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//single label on candidate
export const attachLabelToCandidate = (req: attachLabelToCandidateReq) => {
  const ep = epCDB + `labels/attach/tocandidate`;
  return new Promise<candidateLabel>((resolve, reject) => {
    api
      .post<attachLabelToCandidateRes>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.candidate_labels);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
//bulk label on candidate
export const attachBulkLabelsToCandidates = (
  req: deleteLabelFromCandidateReq
) => {
  const ep = epCDB + `labels/bulk/tocandidate`;
  return new Promise<reviewsLabel>((resolve, reject) => {
    api
      .post<attachBulkLabelsToCandidatesRes>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.candidate_labels);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const editCandidate = (candidateReq: any) => {
  const ep = epCDB + `candidates`;

  return new Promise<Candidate>((resolve, reject) => {
    api
      .patch<getCandidateRes>(ep, candidateReq, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.candidates);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getAttachments = (model: string, recordId: string) => {
  const ep = epCDB + `attachments?model_name=${model}&record_id=${recordId}`;
  return new Promise<Attachment[]>((resolve, reject) => {
    api
      .get<getApplicantAttachmentsRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.attachments);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const deleteAttachmentOfCandidate = (recordId: string) => {
  const ep = epCDB + `attachments?id=${recordId}`;
  return new Promise<Attachment[]>((resolve, reject) => {
    api
      .delete<getApplicantAttachmentsRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.attachments);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//get candidate boards
export const getCandidateApplication = (candidateId: string) => {
  const ep = epCDB + `candidates/cards?id=${candidateId}`;
  return new Promise<Applicant[]>((resolve, reject) => {
    api
      .get<getCandidateApplicationRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.candidate_applicants);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getReviewComments = (id: string) => {
  const ep = epCDB + `comments?id=${id}`;
  return new Promise<ReviewComment[]>((resolve, reject) => {
    api
      .get<getReviewCommentsRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.comments);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const createReviewComment = (req: AddCommentOnReviewReq) => {
  const ep = epCDB + `comments`;
  return new Promise<ReviewComment>((resolve, reject) => {
    api
      .post<createReviewCommentRes>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          resolve(res.data.comments);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//assign memner
export const assignMemberToReview = (req: AssignMemberToReviewReq) => {
  const ep = epCDB + `members/attach/toreview`;
  return new Promise<AssignMemberToReview>((resolve, reject) => {
    api
      .post<assignMemberToReviewRes>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          resolve(res.data.review_members);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const uploadAttachmentOfCandidate = (
  formData: any,
  modelName: string,
  recordId: string,
  org_id:string,
  user_id:string,
) => {
  const ep =
    epCDB + `attachments/pub?model_name=${modelName}&record_id=${recordId}&org_id=${org_id}&user_id=${user_id}&type=form`;
  return new Promise<Attachment[]>((resolve, reject) => {
    api
      .post<uploadAttachmentOfCandidateRes>(ep, formData, {
        headers: atsUploadHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.attachments);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const attachLabelToReview = (req: any) => {
  const ep = epCDB + `labels/attach/toreview`;
  return new Promise<candidateLabel>((resolve, reject) => {
    api
      .post<attachLabelToReviewRes>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.review_labels);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const updateCandidateAttachment = (attachment: any) => {
  const ep = epCDB + "attachments";
  return new Promise<Attachment>((resolve, reject) => {
    api
      .patch<updateCandidateAttachmentRes>(ep, attachment, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.attachments);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
// check otp
export const checkPhoneOtp = (req: checkOtpToUserPhoneReq) => {
  const ep = epFusion + "mobile/otp/check";
  return new Promise<sentOtpToUserPhoneRes>((resolve, reject) => {
    api
      .post<sentOtpToUserPhoneRes>(ep, req, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        reject(reason);
      });
  });
};

export const getCandidateReviews = (cId: string) => {
  const ep = epCDB + `reviews/candidate?id=${cId}`;
  return new Promise<CandidateShortReview[]>((resolve, reject) => {
    api
      .get<getReviewsOfSelectedCandidate>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.reviews);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const removeMemberFromCurrentReview = (req: any) => {
  const ep = epCDB + "members/attach/toreview";
  return new Promise<AssignMemberToReview>((resolve, reject) => {
    api
      .delete<assignMemberToReviewRes>(ep, {
        headers: authHeader(),
        data: req,
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.review_members);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const CreateCandidateCard = (req: any) => {
  const ep = epCDB + `candidates/card`;
  return new Promise<candidateLabel>((resolve, reject) => {
    api
      .post<any>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const CloseReview = (ids: {
  reviews: string[] | React.Key[]
}) => {
  const ep = epCDB + `reviews/close`;
  return new Promise<CandidateShortReview[]>((resolve, reject) => {
    api
      .post<any>(ep, ids, {
          headers: authHeader(),
        }
      )
      .then((res) => {
        if (res.status == 200)
          resolve(res.data.reviews);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const editCDBRole = (req: any) => {
  const ep = epCDB + "members/edit/role";
  return new Promise<Member>((resolve, reject) => {
    api
      .put(ep, req, {
        headers: authHeader(),
      })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.members);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const removeCandidateMember = (req: any) => {
  const ep = epCDB + "members";
  return api.delete(ep, { headers: authHeader(), data: req });
};

export const editCommentOfReview = (req: EditCommentOfReviewReq) => {
  const ep = epCDB + "comments";
  return new Promise((resolve, reject) => {
    api
      .patch<editCommentOfReviewRes>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.comments);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const addLabelToBulkReview = (req: addLabelsToBulkReviewReq) => {
  const ep = epCDB + `labels/bulk/toreview`;
  return new Promise<bulkLabel[]>((resolve, reject) => {
    api
      .post<addLabelToBulkReviewRes>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.reviews_labels);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const addMemberToBulkReview = (req: addMembersToBulkReviewReq) => {
  const ep = epCDB + `members/bulk/toreview`;
  return new Promise<bulkMember[]>((resolve, reject) => {
    api
      .post<addMembersToBulkReviewRes>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.review_members);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

/******************************************Tacitmail  *******************************/

//create domain
export const addCustomDomain = (req: addCustomDomainReq) => {
  const ep = epMail + `domain/switch`;
  return new Promise<Domain>((resolve, reject) => {
    api
      .post<addCustomDomainRes>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.domain);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//verify domain

export const verfityDomain = (domaninId: string) => {
  const ep = epMail + `domain/verify?id=${domaninId}`;
  return new Promise<Domain>((resolve, reject) => {
    api
      .get<verifyDomainRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.verified_domain);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//get domain
export const getDomain = () => {
  const ep = epMail + `domain`;
  return new Promise<Domain>((resolve, reject) => {
    api
      .get<addCustomDomainRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.domain);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
//create ses identity

export const CreateSESIdebtity = (domaninId: string) => {
  const ep = epMail + `domain/verify?id=${domaninId}`;
  return new Promise<bulkMember[]>((resolve, reject) => {
    api
      .post<addMembersToBulkReviewRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.review_members);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//send message

export const sendMail = (req: sendMailReq) => {
  const ep = epMail + `imbox/compose`;
  return new Promise<SendMail>((resolve, reject) => {
    api
      .post<sendMailres>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.message);
      })
      .catch((reason: AxiosError) => {
        // throwError(reason)
        reject(reason);
      });
  });
};

//get user imbox
export const getUserInbox = (tab: string, currentPage: number,
  pageSize: number) => {
  const ep = epMail + `imbox?page=${currentPage}&per_page=${pageSize}&filter=${tab}`;
  return new Promise<paginationDetails & paginationMail>((resolve, reject) => {
    api
      .get<getUserInboxRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.imboxs);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//get message thread
export const getThreadOfMessage = (id: string, key: string) => {
  const ep = epMail + `imbox/threads?record_id=${id}&initial_key=${key}`;
  return new Promise<RecordThreads>((resolve, reject) => {
    api
      .get<getThreadOfMessageRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.record_threads);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//get message thread
export const getUserThreadOfMessage = (id: string) => {
  const ep = epMail + `imbox/user/threads?message_id=${id}`;
  return new Promise<RecordThreads>((resolve, reject) => {
    api
      .get<getThreadOfMessageRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.record_threads);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//mark mail as read
export const markMailAsRead = (id: string) => {
  const ep = epMail + `imbox/read?id=${id}`;
  return new Promise<Mail>((resolve, reject) => {
    api
      .put<markMailAsReadRes>(ep, id, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.message);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//mark message as important
export const markMailAsImportant = (id: string) => {
  const ep = epMail + `imbox/read?id=${id}&important=true`;
  return new Promise<Mail>((resolve, reject) => {
    api
      .post<markMailAsReadRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.message);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//add attachment to mail
export const addAttachmentToMail = (formData: any, onUploadProgress: any) => {
  const ep = epMail + `imbox/attachments`;
  return new Promise<Attachment[]>((resolve, reject) => {
    api
      .post<getApplicantAttachmentsRes>(ep, formData, {
        headers: authHeader(),
        onUploadProgress,
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.attachments);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const downloadAttachmentOfMail = (objectKey: string) => {
  const ep = epMail + `imbox/attachments?key=${objectKey}`
  return new Promise((resolve, reject) => {
    api
      .get(ep, {
        headers: authHeader(),
      })
      .then((res: any) => {
        if (res.status == 200) resolve(res.data.presign)
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
};

//get record mail preview
export const getRecordMailPreview = (id: string) => {
  const ep = epMail + `imbox/record?record_id=${id}`;
  return new Promise<paginationDetails & paginationMail>((resolve, reject) => {
    api
      .get<getUserInboxRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.imboxs);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// linkedin source email

export const getLinkedinSourceEmail = (id: string) => {
  const ep = epMail + `imbox/address?id=${id}&service=ats&entity=job-post`;
  return new Promise<any>((resolve, reject) => {
    api
     .get<any>(ep, {
        headers: authHeader(),
      })
     .then((res) => {
        if (res.status == 200) resolve(res.data.address);
      })
     .catch((reason: AxiosError) => {
        reject(reason);
      });
  });
}

// ********* Plugin ***********

// getAllMappings
export const getAllMappings = () => {
  const ep = epCDB + `plugin/mappings`;
  return new Promise<getAllMappingsRes>((resolve, reject) => {
    api
      .get<getAllMappingsRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// getMapping

export const getMapping = () => {
  const ep = epCDB + `plugin/mapping?app_name=Linkedin`;
  return new Promise<getMappingRes>((resolve, reject) => {
    api
      .get<getMappingRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// updateMapping

export const updateMapping = (m_id: string, req: any) => {
  const ep = epCDB + `plugin/mapping?mapping_id=${m_id}`;
  return new Promise<updateMappingRes>((resolve, reject) => {
    api
      .put<updateMappingRes>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};


/********************************************** form builder **********************************************************/

//create form 
export const createForm = (formData?: FormData) => {
  const ep = epJD + `form`
  return new Promise<FormBuilder>((resolve, reject) => {
    api
      .post(ep, formData, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.form)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

// get form
export const getAllBuildForm = () => {
  const ep = epJD + `form_builder`
  return new Promise<FormBuilder[]>((resolve, reject) => {
    api
      .get(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.forms)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

//get form by id
export const getFormById = (formId: string) => {
  const ep = epJD + `form_builder` + `/${formId}`
  return new Promise<FormBuilder>((resolve, reject) => {
    api
      .get(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data.form)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

//create jd
export const createCandidateFromLink = (formData?: FormData) => {
  const ep = epCDB + `candidates/from/JD`
  return new Promise<FormBuilder>((resolve, reject) => {
    api
      .post(ep, formData)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

//update form
export const updateFormById = (formId: string, formData: any) => {
  const ep = epJD + `form_builder` + `/${formId}`
  return new Promise((resolve, reject) => {
    api
      .patch(ep, formData, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.form)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}


/************************************************ JD  *****************************/

//create jd
export const createJd = (jdData?: FormData) => {
  const ep = epJD
  return new Promise<Jd>((resolve, reject) => {
    api
      .post(ep, jdData, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.jd)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
export const getAllJobDescription = () => {
  const ep = epJD
  return new Promise<Jd[]>((resolve, reject) => {
    api
      .get(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.jds)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
export const getAllJobOfOrg = async (orgId: string, page: number, perPage: number) => {
  const ep = `v1/public/public-service/all/orgjd?org_id=${orgId}&page=${page}&per_page=${perPage}`;
  try {
    const res = await api.get(ep);
    if (res.status === 200) {
      return res.data.jds;
    }
  } catch (error) {
    console.error("Failed to fetch jobs:", error);
    throw error; 
  }
};


// getting available integrations
export const getAvailableIntegrations = async ()=>{
  const ep = epJD + "integrations/"
  return new Promise<any>((resolve, reject) => {
    api
      .get(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.integrations)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
//get integration of jd
export const getIntegrationsOfJD = (jd_id: string) => {
  const ep = epJD + "integrations/" + jd_id
  return new Promise<any>((resolve, reject) => {
    api
      .get(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.jd_integrations)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
//update integration of jd
export const updateIntegrationStatus = (jd_id: string, req: any) => {
  const ep = epJD + "integrations/" + jd_id
  return new Promise<{ jd_id: string, integrations: Integrations[] }>((resolve, reject) => {
    api
      .patch(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.jd_integrations)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

//attached form
export const attachedFormToJD = (req: any) => {
  const ep = epJD + "attached_form_to_jd"
  return new Promise<{ id: "", title: "" }>((resolve, reject) => {
    api
      .put(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.jd_form)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
//add reviewer
export const addReviewerToJd = (req: any) => {
  const ep = epJD + "reviewers"
  return new Promise<any[]>((resolve, reject) => {
    api
      .post(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.reviwers)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
//get all publish form
export const getAllPublishForms = () => {
  const ep = epJD + "form/published"
  return new Promise<FormBuilder[]>((resolve, reject) => {
    api
      .get(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.forms)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
//detached form
export const dettachedFormFromJD = (req: any) => {
  const ep = epJD + "dettached_form_to_jd"
  return new Promise<{ id: "", title: "" }>((resolve, reject) => {
    api
      .put(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.jd_form)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
//getjdby id form
export const getJDById = (Jd_id: string) => {
  const ep = epJD + Jd_id
  return new Promise<Jd>((resolve, reject) => {
    api
      .get(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.jd)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

//updateJD
export const updateJD = (Jd_id: string, req: any) => {
  const ep = epJD + Jd_id
  return new Promise<Jd>((resolve, reject) => {
    api
      .patch(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.jd)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
//public JD url
export const getPublicJDById = (params: any) => {
  const ep = `/v1/public/public-service` + `/${params.jd_id}`
  return new Promise<any>((resolve, reject) => {
    api
      .get(ep)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.jd)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

export const getJDbyBoardId = (boardId: string) => {
  const ep = epJD + `board/${boardId}`; 

  return new Promise<Jd>((resolve, reject) => {
    api.get(ep,{ headers: authHeader() }).then((res) => {
      if (res.status === 200) {
        resolve(res.data.jd);
      } else {
        reject(new Error('Failed to fetch job description'));
      }
    }).catch((reason: AxiosError) => {
      reject(reason);
    });
  });
};

// get JD members

export const showJDMember = () => {
  const ep = epJD + `members`;
  return new Promise<Member[]>((resolve, reject) => {
    api
      .get(ep, {
        headers: authHeader(),
      })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.members);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};


// add members
export const addMemberToJD = (req: AddMemberReq) => {
  const ep = epJD + `members`;
  return new Promise<Member[]>((resolve, reject) => {
    api
      .post(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.members);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
// Remove member
export const removeJDMember = (req: any) => {
  const ep = epJD + "members";
  return api.delete(ep, { headers: authHeader(), data: req });
};

// Edit role 
export const editJDRole = (req: any) => {
  const ep = epJD + "members";
  return new Promise<Member>((resolve, reject) => {
    api
      .put(ep, req, {
        headers: authHeader(),
      })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.members);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};


// ******************* Import ********************** //
// file upload
export const ImportFileUpload = (req: any,onUploadProgress: any) => {
  const ep = epCDB + `candidates/import`;
  return new Promise<Stage>((resolve, reject) => {
    api
      .post<Stage>(ep, req, {
        headers: authHeader(),        onUploadProgress,


      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//import
export const CSVCandidateImport = (req: any) => {
  const ep = epCDB + `candidates/import/data`;
  return new Promise<Stage>((resolve, reject) => {
    api
      .post<Stage>(ep, req, {
        headers: authHeader(),       


      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};


// get Import reports
export const getImportReports = () => {
  const ep = epCDB + `candidates/import`;
  return new Promise<ImportReport[]>((resolve, reject) => {
    api
      .get(ep,  {
        headers: authHeader(),       


      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          resolve(res.data.reports);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};




/*********************************************** Campany creation  *********************************************/

export const createCompany = (req: company) => {
  const ep = epCompanies
  let startTime = performance.now()
  return new Promise<company>((resolve, reject) => {
    api
      .post<{ companies: company }>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        let endTime = performance.now()
        let elapsedTime = endTime - startTime
        if (elapsedTime < 1500) {
          setTimeout(() => {
            resolve(res.data.companies) 
          }, 1500 - elapsedTime)
        }else{
          if (res.status == 200) {
            resolve(res.data.companies) 
          }
        }
       
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

export const getCompanies = () => {
  const ep = epCompanies + "/all"
  return new Promise<company[]>((resolve, reject) => {
    api
      .get<{ companies: company[] }>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.companies)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

export const updateCompantDetails = (req: any) => {
  const ep = epCompanies
  return new Promise<company>((resolve, reject) => {
    api
      .patch<{ companies: company }>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.companies)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
export const addMemberToCompanies = (req: AddMemberReq) => {
  const ep = epCompanies + `/members`
  return new Promise<Member[]>((resolve, reject) => {
    api
      .post(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.members)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

export const getCompaniesMembers = () => {
  const ep = `${epCompanies}/members`
  return new Promise<Member[]>((resolve, reject) => {
    api
      .get<getOpeningMembersRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          res.data.members = res.data.members ? res.data.members : []
          resolve(res.data.members)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}


// removeMemberFromCompany
export const removeCompanyMember = (req: any) => {
  const ep = epCompanies + `/members`

  return new Promise<BillingMember>((resolve, reject) => {
    api
      .delete<BillingMember>(ep, { headers: authHeader(), data: req })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data)
        } else {
          alert("invalid credentials")
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason)
        }
      })
  })
}
export const assignManagerToCompany = (req: any) => {
  const ep = epCompanies + `/members/tocompany`

  return new Promise<any>((resolve, reject) => {
    api
      .post<any>(ep, req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data.members)
        } else {
          alert("invalid credentials")
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason)
        }
      })
  })
}
export const removeManager = (req: any) => {
  const ep = epCompanies + `/members/tocompany`
  return new Promise<any>((resolve, reject) => {
    api
      .delete<any>(ep, { headers: authHeader(), data: req })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data.members)
        } else {
          alert("invalid credentials")
        }
      })
      .catch((reason: AxiosError) => {
        if (reason.response?.status == 404) {
          reject(reason)
        }
      })
  })
}


//attach company
export const attachBoardToWorkspace = (req: { id: string, company_id: string }) => {
  const ep = epBoard + "attach/company"
  return new Promise<Opening>((resolve, reject) => {
    api
      .put<OpeningRes>(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200 && res.data.opening) {
          resolve(res.data.opening)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}






//analytics
export const getAnalytics = (role: string) => {
  const ep = `${epAnalytics}members/analyticsdash?role=${role}`
  return new Promise<Dashboardanalytic>((resolve, reject) => {
    api
      .get<getDashboardAnalytics>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.analytics)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

//range 
export const getAnalyticsHistory = (role: string, firstData: string, lastDate: string) => {
  const ep = `${epAnalytics}members/analyticsdash?role=${role}&first_date=${firstData}&end_date=${lastDate}`
  return new Promise<Dashboardanalytic>((resolve, reject) => {
    api
      .get<getDashboardAnalytics>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.analytics)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

export const checkIsManager = () => {
  const ep = `${epCompanies}/members/ismanager`
  return new Promise<boolean>((resolve, reject) => {
    api
      .get<{ managers: boolean }>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.managers)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}



//boardAnalytics
export const boardAnalytics = () => {
  const ep = `${epAnalytics}members/analyticsboard`
  return new Promise<BoardStatistics>((resolve, reject) => {
    api
      .get(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.board_statistics)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}



// NotificationCenter

//boardAnalytics
export const getRTUUpdatesOfOrg = (paramter:string) => {
  let ep = `${epRTU}/pvt/workers`;
  

  if (paramter) {
    // If req is provided, append the parameters to the endpoint
    ep += `?${paramter}`;
  }  return new Promise<Notifications>((resolve, reject) => {
    api
      .get<getRTURes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.notifications)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

// org email notifications
export const updateUserNotificationTime = (payload: NotificationSettingsPayload)=>{
  let ep = `${epRTU}/pvt/workers/user-notification-setting`;
  return new Promise<UserNotificationSettings>((resolve,reject)=>{
    api.patch<any>(ep, payload,{headers: authHeader()})
    .then((res:any)=>{
      if(res.status == 200){
        resolve(res.data.setting)
      }
    })
    .catch((reason: AxiosError)=>{
      console.error("Error updating notification settings:", reason);
      reject(reason);
    })
  })
}

// get user timezone

export const getUserNotificationTime = ()=>{
  let ep = `${epRTU}/pvt/workers/user-notification-setting`;
  return new Promise<UserNotificationSettings>((resolve,reject)=>{
    api.get<any>(ep,{headers: authHeader()})
    .then((res:any)=>{
      if(res.status == 200){
        resolve(res.data.setting)
      }
    })
    .catch((reason: AxiosError)=>{
      console.error("Error while getting timezone settings:", reason);
      reject(reason);
    })
  })
}

// getUserLastVisitedTime 
export const getUserLastVisitedTime = () => {
  let ep = `${epRTU}/pvt/workers/lastvisited`;
  

   return new Promise<lastVisited>((resolve, reject) => {
    api
      .get<lastVisitedRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.last_visited_activity)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

export const setUserLastVisitedTime = (time:number) => {
  let ep = `${epRTU}/pvt/workers/lastvisited?time=${time}`;


   return new Promise<lastVisited>((resolve, reject) => {
    api
      .put<lastVisitedRes>(ep,null, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.last_visited_activity)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}


export const readAllNotificationsOfEntity = (user_id:string,subscription_id:string) => {
  let ep = `${epRTU}/pvt/workers/readall?user_id=${user_id}&&subscription_id=${subscription_id}`;
   return new Promise((resolve, reject) => {
    api
      .put(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.time)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
export const readNotification = (model_id:string|null,model_name:string|null) => {
  let ep = `${epRTU}/pvt/workers/readall?model_id=${model_id}&model_name=${model_name}&socket_client_id=${sessionStorage.getItem(SOCKET_CLIENT_ID)}`;  
   return new Promise((resolve, reject) => {
    api
      .put(ep,null, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.time)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
export const markAllAsRead= () => {
  let ep = `${epRTU}/pvt/workers/readall`;


   return new Promise((resolve, reject) => {
    api
      .put(ep,null, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.time)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
//get watcher
export const getUserWatcherOfModel = (model_id:string|null,model_name:string|null) => {
  let ep = `${epRTU}/pvt/workers/watch?model_type=${model_name}&model_id=${model_id}`;
   return new Promise<getWatcherOfModelRes>((resolve, reject) => {
    api
      .get<getWatcherOfModelRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {          
          resolve(res.data)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
// set watcher
export const setUserWatcherOfModel = (model_id:string|null,model_name:string|null,watch:boolean) => {
  let ep = `${epRTU}/pvt/workers/watch`;
   return new Promise((resolve, reject) => {
    api
      .put<getWatcherOfModelRes>(ep,{watch:watch,model_type:model_name,model_id:model_id}, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.watcher)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}


//api for getting data graphwise


export const getGraphDataByChartType = (
  graph_type: string,
  req: {
    graph_type: string;
    template_id: string;
    entity_type?: string;
    entity_names_string?: string;
    start_time?: string;
    end_time?:string,
    time_zone:string;
    page?: number;
    page_size?: number;
    
  }
) => {
  let ep = `${epAnalytics}generate-graph?type=${req.graph_type}&template_id=${req.template_id}&entity_type=${req.entity_type}${req.entity_names_string}&start_time=${req.start_time}&end_time=${req.end_time}&time_zone=${req.time_zone}`;

  if (req.page && req.page_size) {
    ep += `&page=${req.page}&page_size=${req.page_size}`;
  }

  return new Promise<any>((resolve, reject) => {
    api
      .get<ReactEChartsOptions>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.analytics);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// Api Function for the  fetching the templates of graph

export const getGraphLayout = (req?: {
  group_id?: string;
}): Promise<LayoutData[]> => {
  const ep = `${epAnalytics}templates`;
  return new Promise<LayoutData[]>((resolve, reject) => {
    api
      .get<LayoutsOptions>(ep, { params: req, headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.templates);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
// orgIntegration
export const getOrgintegrations = () => {
  let ep = `${epCommunicationIntegration}/auth/orgintegrations`;
   return new Promise<Integration[]>((resolve, reject) => {
    api
      .get<getOrgAppIntegrationRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {          
          resolve(res.data.integrations)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
  
}

//Get user integration for slack 
export const getUserintegrations = () => {
  let ep = `${epCommunicationIntegration}/members/integrations`;
   return new Promise<UserIntegration[]>((resolve, reject) => {
    api
      .get<any>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {          
          resolve(res.data.user_integrations)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
  
}

//Delete the user slack integration

export const deleteUserIntegration = (client:string) => {
  let ep = `${epCommunicationIntegration}/auth/user/registration?client=${client}`;

  return new Promise<any>((resolve, reject) => {
    api
      .delete<any>(ep, { headers: authHeader() })
      .then((res: any) => {                
        if (res.status == 200 || res.status == 201) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//Delete the slack integration for organisation

export const deleteOrgIntegration = (client:string) => {
  let ep = `${epCommunicationIntegration}/auth/integration?client=${client}`;

  return new Promise<any>((resolve, reject) => {
    api
      .delete<any>(ep, { headers: authHeader() })
      .then((res: any) => {                
        if (res.status == 200 || res.status == 201) {
          resolve(res.data);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//api function for updating the position of layouts
export const updateLayoutsPosition = ( req:{layouts:any[] }) => {
  const ep = `${epAnalytics}update-templates`
  return new Promise<void>((resolve, reject) => {
    api
    .post<any>(ep, req, { headers: authHeader() })
    .then((res) => {
      if (res.status === 200) {
        resolve();
      } else {
        reject(new Error("Failed to attach layouts to workspace"));
      }
    })
    .catch((reason: AxiosError) => {
      throwError(reason);
      reject(reason);
    });
});
};

// getAppwiseMembers
export const getAppwiseMembers = (paramter:string) => {
  let ep = `${epCommunicationIntegration}/members`;
  if (paramter) {
    ep += `?${paramter}`;
  }
   return new Promise<Members>((resolve, reject) => {
    api
      .get<getIntegratedAppMemberRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {          
          resolve(res.data.members)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}






//  Communication integration

export const communicationIntegration = (client:string,code:string) => {
  let ep = `${epCommunicationIntegration}/auth/integration?client=${client}&code=${code}`;

  return new Promise<Integration>((resolve, reject) => {
    api
      .post<getOrgAppIntegrationRes>(ep, null, { headers: authHeader() })
      .then((res: any) => {                
        if (res.status == 200 || res.status == 201) {
          resolve(res.data.integration);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//Account User Integrations

export const AccountUserIntegration = (client:string) => {
  let ep = `${epCommunicationIntegration}/auth/user/registration?client=${client}`;

  return new Promise<any>((resolve, reject) => {
    api
      .post<any>(ep, null, { headers: authHeader() })
      .then((res: any) => {                
        if (res.status == 200 || res.status == 201) {
          resolve(res.data.integration);
        } else {
          alert("invalid credentials");
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// getBillingActivePlanAPI
export const getBillingCurrentlyActivePlan = (cust_id:string,org_id:string) => {
  let ep = `${epDbmanager}/billing/active/plan?cust_id=${cust_id}&org_id=${org_id}`;
 
   return new Promise<Billings>((resolve, reject) => {
    api
      .get<getBillingActivePlan>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {          
          resolve(res.data.billings)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}




/*******************************Job description management *********************/



export const createJobDescription = (reqObj: FormData): Promise<any> => {
  const ep = epJD;
  let startTime = performance.now()
  return new Promise((resolve, reject) => {
    api.post(ep, reqObj, { headers: authHeader() })
      .then((res) => {
        let endTime = performance.now() // End timing when response arrives
        let elapsedTime = endTime - startTime 
        if (elapsedTime < 1500) {
          // If response arrives within 300 milliseconds
          setTimeout(() => {
            resolve(res.data.jd); // Resolve after 500 milliseconds
          }, 1500 - elapsedTime)
        } else {
          if (res.status == 200 || res.status == 201) {
            resolve(res.data.jd);
          }
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const createFormTemplate = (reqObj: FormData): Promise<any> => {
  const ep = `${epJD}/form_builder`;
  let startTime = performance.now()
  return new Promise((resolve, reject) => {
    api.post(ep, reqObj, { headers: authHeader() })
      .then((res) => {
        let endTime = performance.now() // End timing when response arrives
        let elapsedTime = endTime - startTime;

        if (elapsedTime < 1500){
          setTimeout(() => {
            resolve(res.data.form);// Resolve after 500 milliseconds
          }, 1500 - elapsedTime)
        }else{
          if (res.status == 200 || res.status == 201) {
            resolve(res.data.form);
          }
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};




export const getFormTemplate =  () => {
  const ep = epJD + '/template'
  return new Promise<FormTemplate[]>((resolve, reject) => {
    api
      .get<{template:FormTemplate[]}>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {          
          resolve(res.data.template)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
};

export const getFormTemplateById = async (form_template_id:string) => {
  const url =`https://7cd40fb0-b75b-43d7-8f82-fe3ecd98f2a9.mock.pstmn.io/template/form+${form_template_id}`
  try {
    const response = await api.get(url);
    return response.data.form;
  } catch (error) {
    // Handle error
    console.error('Error fetching form template:', error);
    throw error;
  }
}






/********************************************* GPT  ****************************************************/


export const generateJDUsingGpt = (newOpening: {}) => {
  const ep = epGPT + "/prompts"
  return new Promise((resolve, reject) => {
    api
      .post(ep, newOpening, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          resolve(res.data.prompts)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
};



//api for updating analytics list label 

export const updateAnalyticsListLabels =(list_id:string,id:string,req:{id:string,name:string,color:string,})=>{
  const ep = `${epLabels}analytics?list_id=${list_id}&label_id=${id}`
  return new Promise<any>((resolve, reject) => {
    api
    .put<any>(ep, req, { headers: authHeader() })
    .then((res) => {
      if (res.status === 200) {
        resolve(res.data.label);
      } else {
        reject(new Error("Failed to update the analytics label to list."));
      }
    })
    .catch((reason: AxiosError) => {
      throwError(reason);
      reject(reason);
    });
});
}


export const getAnalyticsListLabel =()=>{
  const ep = `${epLabels}analytics` 
  return new Promise<getAnalyticsLabelsDataType[]>((resolve, reject) => {
    api
    .get<getAnalyticsLabelsType>(ep,{ headers: authHeader() })
    .then((res) => {
      if (res.status === 200) {
        resolve(res.data.labels);
      } else {
        reject(new Error("Failed to get the analytics labels."));
      }
    })
    .catch((reason: AxiosError) => {
      throwError(reason);
      reject(reason);
    });
});
}

export const ResumeParserAttachment = (formData: any) => {
  const ep = epCDB + `candidates/resumes`;
  return new Promise<any>((resolve, reject) => {
    api
      .post<any>(ep, formData, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.candidates);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};



export const ValidateOrganizationCompanyName = (name:string) => {
  const ep = epDbmanager + `orgs/validate/orgname?org_name=${name}`;
  return new Promise<any>((resolve, reject) => {
    api
      .get<any>(ep, {
        headers: authHeader1(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.validate);
      })
      .catch((reason: AxiosError) => {
        if (reason.response) {
          reject(reason.response.data.error.message);
        } else {
          reject("Validation failed");
        }
      });
  });
};


export const ValidateOrganizationDisplayName = (name:string) => {
  const ep = epDbmanager + `orgs/validate/displayname?org_display_name=${name}`;
  return new Promise<any>((resolve, reject) => {
    api
      .get<any>(ep, {
        headers: authHeader1(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.validate);
      })
      .catch((reason: AxiosError) => {
        if (reason.response) {
          reject(reason.response.data.error.message);
        } else {
          reject("Validation failed");
        }
      });
  });
};


export const ValidateOrganizationCareerPageURL = (url:string) => {
  const ep = epDbmanager + `orgs/validate/carrerpagename?org_career_page_name=${url}`;
  return new Promise<any>((resolve, reject) => {
    api
      .get<any>(ep, {
        headers: authHeader1(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.validate);
      })
      .catch((reason: AxiosError) => {
        if (reason.response) {
          reject(reason.response.data.error.message);
        } else {
          reject("Validation failed");
        }
      });
  });
}
export const getBillingActivePlans = (product_type:string)=>{
  const ep = epDbmanager + `billing/plans?product_type=${product_type}`
  return new Promise<any>((resolve, reject) => {
    api.get<any>(ep, {
      headers: authHeader1(),
    }).then((res)=>{
      if(res.status == 200){
        resolve(res.data)
      }
    }).catch(()=>{
          reject("Validation failed");
    })
  })
}


export const getSubscriptionDetails = ()=>{
  const ep = epDbmanager + `billing/subscription-details`
  return new Promise<any>((resolve, reject) => {
    api.get<any>(ep, {
      headers: authHeader(),
    }).then((res)=>{
      if(res.status == 200){
        resolve(res.data)
      }
    }).catch(()=>{
          reject("Validation failed");
    })
  })
}

// redirect link api
export const redirectToNewJD = (jobId: string) => {
  const ep = epPublicService + `links/careerpage/job?job_id=${jobId}`
  return api.get(ep)
    .then((res) => {
      if (res.status === 200) {
        return res.data.links
      }
    })
    .catch((error: AxiosError) => {
      throwError(error)
      throw error // Re-throw the error to propagate it further
    })
}

export const redirectToNewForm = (form_id: string) => {
  const ep = epPublicService + `links/careerpage/form?form_id=${form_id}`
  return api.get(ep)
    .then((res) => {
      if (res.status === 200) {
        return res.data.links
      }
    })
    .catch((error: AxiosError) => {
      throwError(error)
    })
}

export const redirectToCareerPage = (org_id: string) => {
  const ep = epPublicService + `links/careerpage/?org_id=${org_id}`
  return api.get(ep)
    .then((res) => {
      if (res.status === 200) {
        return res.data.links
      }
    })
    .catch((error: AxiosError) => {
      throwError(error)
    })
};


//Function for fetching the all sources 
export const getAllSources = () => {
  const ep = epJD + "source";
  return new Promise<Source[]>((resolve, reject) => {
    api
      .get<SourcesResponse>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.source_tracking);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
}


//getApplicantStatus
export const getApplicantStatus = (ids: { ids: string[] }) => {
  const ep = epCDB + "candidates/bulk/activity"
  return new Promise((resolve, reject) => {
    api
      .post(ep, ids, {
        headers: authHeader(),
      },)
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.activities)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}

// generate magicLin

export const generateMagicLink = (secret:string)=>{
  const ep = epFusion +`login/secret/regenerate?secret=${secret}`
  return new Promise((resolve, reject) => {
    api.post(ep,null,{
      headers: authHeader(),
      }).then((res)=>{
      if (res.status == 200) {
        resolve(res)
      }
    }).catch((reason: AxiosError) => {
      reject(reason)
    })
  })
}

export const resendMagicLink = (secret:string)=>{
  const ep = epFusion +`login/secret/resent?secret=${secret}`
  return new Promise((resolve, reject) => {
    api.post(ep,null,{
      headers: authHeader()
      }).then((res)=>{
      if (res.status == 200) {
        resolve(res)
      }
    }).catch((reason: AxiosError) => {
      reject(reason)
    })
  })
}

//api functions for slack integration for board notification

export const GetSlackWorkspaceChannels=(params:string)=>{
  let ep = epCommunicationIntegration +`/channels?client=slack`
  if (params) {
    ep += `&${params}`;
  }
  return new Promise<SlackChannels>((resolve, reject) => {
    api.get<getSlackChannelsRes>(ep,{
      headers: authHeader()
      }).then((res)=>{
      if (res.status == 200) {
        resolve(res.data.channels)
      }
    }).catch((reason: AxiosError) => {
      reject(reason)
    })
  })
}

export const GetSlackWorkspaceRefreshChannels=()=>{
  const ep = epCommunicationIntegration +`/channels?client=slack`
  return new Promise((resolve, reject) => {
    api.post(ep,null,{
      headers: authHeader()
      }).then((res)=>{
      if (res.status == 200) {
        resolve(res.data.channels)
      }
    }).catch((reason: AxiosError) => {
      reject(reason)
    })
  })
}

export const deleteSlackChannelIntegrationForBoard = (board_id: string) => {
  const ep = epATSRTU + `/chat-integrations/${board_id}`;
  return new Promise((resolve, reject) => {
    api
      .delete(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const GetChatIntegrationForBoard = (board_id: string) => {
  const ep = epATSRTU + `/chat-integrations/${board_id}`;
  return new Promise((resolve, reject) => {
    api
      .get(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.chat_integration);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const UpdateChatIntegrationForBoard = (board_id: string,req:any) => {
  const ep = epATSRTU + `/chat-integrations/${board_id}`;
  return new Promise((resolve, reject) => {
    api
      .patch(ep,req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.chat_integration);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};


export const GetChatActionsForBoard = () => {
  const ep = epRTU + `/pvt/chats`;
  return new Promise((resolve, reject) => {
    api
      .get(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.actions);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const AddChhanelIntegrationForBoard = (board_id: string,req:any) => {
  const ep = epATSRTU + `/chat-integrations/${board_id}`;
  return new Promise((resolve, reject) => {
    api
      .post(ep,req, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.chat_integration);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//Api calling for the refresh the members of connect slack member popup under the setting member pannel
export const getRefreshSlackMember = () => {
  const ep = epCommunicationIntegration + "/members?client=slack"
  return new Promise((resolve, reject) => {
    api
      .post(ep,null, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}




// calendar


export const fetchEventsForCalendar = (calId: string, timeRange: { timeMin: any, timeMax: any }, userId: string,
  timezone: string
) => {
  return new Promise<fetchEventsForCalendarRes>((resolve, reject) => {

    api
      .get<fetchEventsForCalendarResRoot>(`${epScheduling}events/${calId}?time_max=${timeRange.timeMax}&user_id=${userId}&time_min=${timeRange.timeMin}&time_zone=${timezone}`, { headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.events);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const fetchUserCalendarList = (userId:string) => {
  return new Promise<CalendarListResponse>((resolve, reject) => {

    api
      .get<CalendarListResponseRoot>(`${epScheduling}calendarlist/users/me?user_id=${userId}&max_results&min_access_role&page_token&show_deleted&show_hidden&sync_token`, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.calendar_lists);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const deleteEvent=(calendarId:string,eventId:string,userId:string)=>{
  return new Promise<CalendarListResponse>((resolve, reject) => {
    const url = epScheduling+`events/${calendarId}/${eventId}?user_id=${userId}`

    api
      .delete<CalendarListResponseRoot>(url, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.calendar_lists);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
}



//******************************************** Calender ************************************************************/
export const scheduleEvent = (eventRequest: ScheduleEventRequest,userId:string,calendarId:string,recording_enabled: boolean) => {
  return new Promise<any>((resolve, reject) => {
    const url = epScheduling+"events/"+`${calendarId}`+"?user_id=" +`${userId}`+"&conference_data_version=1&send_updates=all&supported_attachments=true"+`&recording_enabled=${recording_enabled}`

    api
      .post<any>(url, eventRequest, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.events);
        } else {
          reject(new Error(`Unexpected response status: ${res.status}`));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
export const connectCalender = (selectedCalendar: 'google' | 'outlook', userId: string) => {

  const ep = epScheduling+"oauth/authorize?client="+`${selectedCalendar}`+"&user_id="+`${userId}`  
  return new Promise<any>((resolve, reject) => {
    api
      .get(ep,{ headers: authHeader() })
      .then((res: any) => {
        if (res.status == 200) {
          resolve(res.data.auth_code);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
 
};
export const updateScheduledEvent = (eventRequest: ScheduleEventRequest,userID:string,eventID:string,calendarId:string,recording_enabled: boolean) => {
  return new Promise<any>((resolve, reject) => {
    const url = epScheduling+`events/${calendarId}/`+`${eventID}`+"?user_id=" +`${userID}`+"&conference_data_version=1&send_updates=all&supported_attachments=true"+`&recording_enabled=${recording_enabled}`

    api
      .patch<any>(url, eventRequest, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.events);
        } else {
          reject(new Error(`Unexpected response status: ${res.status}`));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// Define the exchangeOAuthCode function
export const exchangeCalenderOAuthCode = ( code: string, state:  string) => {
  return new Promise((resolve, reject) => {
    const url = epScheduling+`oauth/exchange?code=${code}&state=${state}`;
    api
      .post(url,null,{ headers: authHeader1() })
      .then((res) => {
        if (res.status === 200||res.status===201) {
          resolve(res.data); // Resolve with the response data
        } else {
          reject(new Error(`Unexpected response status: ${res.status}`));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason); // Handle the error using your existing error handling function
        reject(reason);
      });
  });
};


export const fetchCalendarColors = (userId:string) => {
  return new Promise<CalendarColorsResponse>((resolve, reject) => {

    api
      .get<CalendarColorsResponseRoot>(`${epScheduling}colors?user_id=${userId}`, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.colors);
        }
      })
      .catch((reason: AxiosError) => {
        // throwError(reason);
        reject(reason);
      });
  });
};

export const getTrackercardEvents = (
  calendarId: string,
  userId: string,
  maxResults: string,
  // boardId: string,
  cardId:string,timezone: string
) => {
  return new Promise<any>((resolve, reject) => {
    const url = epScheduling+`events/${calendarId}?time_zone=${timezone}`;
    
    const params = new URLSearchParams();
    params.append('user_id', userId);
    params.append('max_results', maxResults);
    // params.append('private_extended_property', `board_id=${boardId}`);
    params.append('private_extended_property', `card_id=${cardId}`);
    
    api
      .get(url, { headers: authHeader(), params })
      .then((res) => {
        
        if (res.status === 200) {
          resolve(res.data.events); // Assumes `events` is the data structure returned
        } else {
          reject(new Error("Failed to fetch events."));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getTrackerCardEventById = (
  calendarId: string,
  eventId: string,
  // maxAttendees: string,
  userId: string
) => {
  return new Promise<any>((resolve, reject) => {
    const url = `${epScheduling}events/${calendarId}/${eventId}`;
    
    const params = new URLSearchParams();
    // params.append('max_attendees', maxAttendees);
    // params.append('time_zone', timeZone);
    params.append('user_id', userId);
    
    api
      .get(url, { headers: authHeader(), params })
      .then((res) => {
        
        if (res.status === 200) {
          resolve(res.data.events); // Assumes `event` is the data structure returned
        } else {
          reject(new Error("Failed to fetch the event."));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getJobPostEvents = (
  calendarId: string,
  userId: string,
  boardId: string,timezone:string,
  timeRange: { timeMin: any; timeMax: any }
) => {
  return new Promise<any>((resolve, reject) => {
    const url =
      epScheduling +
      `events/${calendarId}?time_max=${timeRange.timeMax}&time_min=${timeRange.timeMin}&time_zone=${timezone}`;

    const params = new URLSearchParams();

    params.append("private_extended_property", `board_id=${boardId}`);

    params.append("user_id", userId);

    api
      .get(url, { headers: authHeader(), params })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.events); // Assumes `event` is the data structure returned
        } else {
          reject(new Error("Failed to fetch the event."));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getIntegratedCalender = (
  userId: string
) => {
  return new Promise<any>((resolve, reject) => {
    const url = `${epScheduling}oauth?user_id=${userId}`;
    api
      .get(url, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200||res.status === 201) {
          resolve(res.data.data); // Assumes `event` is the data structure returned
        } else {
          reject(new Error("Failed to fetch the event."));
        }
      })
      .catch((reason: AxiosError) => {
        // throwError(reason);
        reject(reason);
      });
  });
}

// export const createGoogleMeetLink = () => {
//   const url = `${epScheduling}events/conf`
//   console.log(url);
//   api
//   .post(url,null, { headers: authHeader() })
//   .then((res) => {
//     if (res.status === 200||res.status === 201) {
//       resolve(res.data); // Assumes `event` is the data structure returned
//     } else {
//       reject(new Error("Failed to fetch the event."));
//     }
//   })
//   .catch((reason: AxiosError) => {
//     throwError(reason);
//     reject(reason);
//   });

  
// }


export const createGoogleMeetLink = (
) => {
  return new Promise<Spaces>((resolve, reject) => {
    const url = `${epScheduling}events/conf`
    api
      .post<createGoogleMeetLinkRes>(url,{}, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200||res.status === 201) {
          resolve(res.data.spaces); // Assumes `event` is the data structure returned
        } else {
          reject(new Error("Failed to fetch the event."));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
}


export const updateUrlAccess = (req: {
  "config":{
        "accessType":string
    }
},spaceName:string
 
) => {
  return new Promise<Spaces>((resolve, reject) => {
    const url = `${epScheduling}events/conf?nameId=${spaceName}`
    api
      .patch<createGoogleMeetLinkRes>(url,req, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200||res.status === 201) {
          resolve(res.data.spaces); // Assumes `event` is the data structure returned
        } else {
          reject(new Error("Failed to fetch the event."));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
}

export const getConferneceDetails = (id:string
 
) => {
  return new Promise<Spaces>((resolve, reject) => {
    const url = `${epScheduling}events/conf?nameId=spaces/${id}`
    api
      .get<createGoogleMeetLinkRes>(url,{ headers: authHeader() })
      .then((res) => {
        if (res.status === 200||res.status === 201) {
          resolve(res.data.spaces); // Assumes `event` is the data structure returned
        } else {
          reject(new Error("Failed to get the event."));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
}



export const removeIntegratedCalander = (
  userId: string
) => {
  return new Promise<any>((resolve, reject) => {
    const url = `${epScheduling}oauth/${userId}`;
    api
      .delete(url, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200||res.status === 201) {
          resolve(res.data); // Assumes `event` is the data structure returned
        } else {
          reject(new Error("Failed to fetch the event."));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
}
export const getfreeBusySlot = (
  userId: string,
  req:any
) => {
  return new Promise<any>((resolve, reject) => {
    const url = `${epScheduling}freebusy?user_id=${userId}`;
    api
      .post(url,req, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200||res.status === 201) {
          resolve(res.data.freebusy); // Assumes `event` is the data structure returned
        } else {
          reject(new Error("Failed to fetch the event."));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
}



export const createCalendar = (calendarReq:{name:string,userId:string,isShared:boolean}) => {
  return new Promise<Calendars>((resolve, reject) => {
    const url = epScheduling+`calendars/?user_id=${calendarReq.userId}&summary=${calendarReq.name}&shared=${calendarReq.isShared}`

    api
      .post<createCalendarRes>(url,null, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.calendars);
        } else {
          reject(new Error(`Unexpected response status: ${res.status}`));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
// getCalendarMembers
export const getCalendarMembers = (calId:string,userId:string) => {
  return new Promise<CalendarMembers>((resolve, reject) => {
    const url = epScheduling+`acl/${calId}?user_id=${userId}&max_result=1&page_token&show_deleted=true&sync_token`

    api
      .get<getCalendarMemberResRoot>(url, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.acl);
        } else {
          reject(new Error(`Unexpected response status: ${res.status}`));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};
// getCalendarMembers
export const getUserCalendarPreferences = () => {
  return new Promise<userCalendarPreference>((resolve, reject) => {
    const url = epScheduling+`users/me/settings/pref`

    api
      .get<getUserCalendarPreferencesResRoot>(url, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.pref);
        } else {
          reject(new Error(`Unexpected response status: ${res.status}`));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// updateUserCalendarPreferences
export const updateUserCalendarPreferences = (req:any) => {
  return new Promise<userCalendarPreference>((resolve, reject) => {
    const url = epScheduling+`users/me/settings/pref`

    api
      .patch<getUserCalendarPreferencesResRoot>(url,req, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.pref);
        } else {
          reject(new Error(`Unexpected response status: ${res.status}`));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

// getCalendarMembers
export const addCalendarMember = (calId:string,userId:string,req:addCalendarMembersReq) => {
  return new Promise<calendarMemberItem>((resolve, reject) => {
    const url = epScheduling+`acl/${calId}?user_id=${userId}&send_notifications=true`

    api
      .post<addCalendarMemberResRoot>(url,req, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.acl);
        } else {
          reject(new Error(`Unexpected response status: ${res.status}`));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};


// getCalendarMembers
export const removeCalendarMember = (calId:string,userId:string,ruleId:string) => {
  return new Promise<calendarMemberItem>((resolve, reject) => {
    const url = epScheduling+`acl/${calId}/${ruleId}?user_id=${userId}`

    api
      .delete<addCalendarMemberResRoot>(url, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.acl);
        } else {
          reject(new Error(`Unexpected response status: ${res.status}`));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};



// getCalendarMembers
export const updateCalendarMemberRole = (calId:string,userId:string,ruleId:string,req:{role:string}) => {
  return new Promise<calendarMemberItem>((resolve, reject) => {
    const url = epScheduling+`acl/${calId}/${ruleId}?user_id=${userId}&send_notifications=true`

    api
      .patch<addCalendarMemberResRoot>(url, req,{ headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.acl);
        } else {
          reject(new Error(`Unexpected response status: ${res.status}`));
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//Api calling for the refresh the members of connect slack member popup under the setting member pannel
export const getJDDataBoardWise = () => {
  const ep = epJD + "board/summary"
  return new Promise<JobBoardSummary[]>((resolve, reject) => {
    api
      .get<JDDataResponse>(ep,{ headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.board_summary)
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason)
        reject(reason)
      })
  })
}
// restrat authorazation stage
export const restartAuthorizationStage = (req:{id:string,user_id:string}) => {
  const ep = epDbmanager + `orgs/authorization`;
  return new Promise<any>((resolve, reject) => {
    api
      .post(ep,req,{ headers: authHeader1() })
      .then((res:any) => {
        if (res.status == 200) {
          resolve(res.data.organisation.setup_stages);
        }
      })
      .catch((reason: AxiosError) => {
        // throwError(reason)
        reject(reason);
      });
  });
};
// restart default domain stage
export const restartCustomDomainStage = (req:{id:string,user_id:string}) => {
  const ep = epDbmanager + `orgs/custom-domain`;
  return new Promise<any>((resolve, reject) => {
    api
      .post(ep,req,{ headers: authHeader1() })
      .then((res:any) => {
        if (res.status == 200) {
          resolve(res.data.organisation.setup_stages);
        }
      })
      .catch((reason: AxiosError) => {
        // throwError(reason)
        reject(reason);
      });
  });
};
export const exportCsv = ( isSelected: boolean,candidates?: string[], boardId?: string) => {
  let ep = epCDB + `candidates/export/csv?isSelected=${isSelected}`
  if (boardId) {
    ep += `&boardId=${boardId}`
  }
  const requestBody = candidates ? { candidates:candidates } : {};
  return new Promise<void>((resolve, reject) => {
    api.post(ep, requestBody, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          throwSuccess("Your export is in progress. We'll send you the download link soon.");
          resolve()
        }
      })
      .catch(() => {
        reject("Error while exporting CSV")
      })
  })
}

/***************E SIGN API'S *********************/
//e-signature
export const getESignAuthUrl = (client:string) => {
  const ep = epESign + `oauth/authorize?client=${client}`;
  return new Promise<string>((resolve, reject) => {
    api
      .get(ep,{ headers: authHeader() })
      .then((res:any) => {
        if (res.status == 200) {
          resolve(res.data.auth_code.url);
        }
      })
      .catch((reason: AxiosError) => {
        reject(reason);
      });
  });
}

// get access token
export const getAccessToken = (code:string,state:string, accountServer?: string,location?: string)=>{
const ep = `${epESign}oauth/exchange?code=${code}&state=${state}${accountServer ? `&accounts-server=${accountServer}` : ''}${location ? `&location=${location}` : ''}`;

return new Promise<void>((resolve, reject) => {
  api
    .post(ep,null,{ headers: authHeader() })
    .then((res:any) => {
      if (res.status == 200) {
        resolve();
      }
    })
    .catch((reason: AxiosError) => {
      reject(reason);
      throwError(reason);
    });
});
}
// get e-sign integration
export const getEsignIntegration = ()=>{
  const ep = epESign + `oauth`;
  return new Promise<any>((resolve, reject) => {
    api
      .get(ep,{ headers: authHeader() })
      .then((res:any) => {
        if (res.status == 201) {
          resolve(res.data.data);
        }
      })
      .catch((reason: AxiosError) => {
        reject(reason);
      });
  });
}

//getting getEmbeddedViewUrl for the e-signature
export const getEmbeddedViewUrl = (id?:string)=>{
  const ep = id? epESign + `embeddedview?id=${id}`:epESign + `embeddedview`;
  return new Promise<string>((resolve, reject) => {
    api
      .get(ep,{ headers: authHeader() })
      .then((res:any) => {
        if (res.status == 200) {
          resolve(res.data.url);
        }
      })
      .catch((reason: AxiosError) => {
        reject(reason);
        throwError(reason);
      });
  });
}

// save documents aginst candidate
export const saveDocumentsAgainstCandidate = (req:{candidate_id:string,document_id:string,jd_id:string})=>{
  const ep = epESign + `saveDetails/${req.candidate_id}/${req.document_id}/${req.jd_id}`;
  return new Promise<void>((resolve,reject)=>{
    api.post(ep,null,{ headers: authHeader() }).then((res)=>{
      if(res.status == 200){
        resolve()
      }
    }).catch((reason:AxiosError)=>{
      reject(reason)
    })
  })
}

//api is for getting document of candidate with jd
export const getAllDocumentsByJd = (job_id:string,candidate_id:string)=>{
  const ep = epESign + `document/${job_id}/${candidate_id}`;
  return new Promise<any>((resolve, reject) => {
    api
     .get(ep,{ headers: authHeader() })
     .then((res:any) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
     .catch((reason: AxiosError) => {
        reject(reason);
      });
  });
}

// get all documents this api if for pool
export const getAllDocumentsByCandidate = (candidate_id?:string)=>{
  const ep = epESign + `document/candidate/${candidate_id}`;
  return new Promise<any>((resolve, reject) => {
    api
     .get(ep,{ headers: authHeader() })
     .then((res:any) => {
        if (res.status == 200) {
          resolve(res.data);
        }
      })
     .catch((reason: AxiosError) => {
        reject(reason);
      });
  });
}

// cancel document signuture process
export const remindDocumentSignatureProcess = (doc_id:string)=>{
  const ep = epESign + `document/remind/${doc_id}`
  return new Promise<any>((resolve,reject)=>{
    api.post(ep,null,{headers:authHeader()}).then((res)=>{
      resolve(res.data)
    }).catch((reason:AxiosError)=>{
      reject(reason)
    })
  })
  }
// download document
  export const downloadEsignDocument = (id:string)=>{
  const ep = epESign + `document/download/${id}`
  return new Promise<any>((resolve,reject)=>{
    api.get(ep,{headers:authHeader()}).then((res)=>{
      resolve(res.data)
    }).catch((reason:AxiosError)=>{
      reject(reason)
    })
  })
  }
// cancel remind signuture process
export const cancelDocumentSignatureProcess = (id:string,cancel_reason?:string)=>{
const ep = cancel_reason?epESign +`document/cancel/${id}?reason=${cancel_reason}` : epESign + `document/cancel/${id}`
return new Promise<any>((resolve,reject)=>{
  api.post(ep,null,{headers:authHeader()}).then((res)=>{
    resolve(res.data)
  }).catch((reason:AxiosError)=>{
    reject(reason)
  })
})
}
// delete user esign integration
// one user have only one esign integration
//based on usertoken we can delete the esign integration
export const deleteUserEsignIntegration = ()=>{
  const ep = epESign + `oauth`;
  return new Promise<any>((resolve, reject) => {
    api
      .delete(ep,{ headers: authHeader() })
      .then((res:any) => {
        if (res.status == 201 || res.status == 200) {
          resolve(res.data);
        }
      })
      .catch((reason: AxiosError) => {
        reject(reason);
      });
  });
}
/*********** Docusign **************/

// extend document signature process
export const extendDocumentSignatureProcess = (id:string,days:string)=>{
  const ep = epESign + `document/extend/${id}?expire_after=${days}`
  return new Promise<any>((resolve,reject)=>{
    api.post(ep,null,{headers:authHeader()}).then((res)=>{
      if(res.status == 200){
        resolve(res.data)
      }
    }).catch((reason:AxiosError)=>{
      reject(reason)
    })
  })
  }


  /******************* Zoho sign ***************/

  // create zoho documet

  export const CreateZohoDocumet = (req:any,jd_id:string,candidate_id:string)=>{
    const ep = epESign +`embeddedview/${jd_id}/${candidate_id}`;
    return new Promise<any>((resolve, reject) => {
      api
        .post(ep,req,{ headers: authHeader() })
        .then((res:any) => {
          if (res.status == 200) {
            resolve(res.data);
          }
        })
        .catch((reason: AxiosError) => {
          reject(reason);
        });
    });
  }

  export const getZohoTemplates = ()=>{
    const ep = epESign + "document/templates";
    return new Promise<any>((resolve, reject) => {
      axios
        .get(ep,{ headers: authHeader() })
        .then((res:any) => {
          if (res.status == 200) {
            resolve(res.data);
          }
        })
        .catch((reason: AxiosError) => {
          reject(reason);
        });
    });
  }

  // emmbedded url
  
  export const getZohoEmbeddedUrl = (req:any,candidate_id:string,job_id:string) => {
    const ep = epESign + `embeddedview/${candidate_id}/${job_id}`;
    return new Promise<any>((resolve, reject) => {
      api
        .post(ep,req,{ headers: authHeader() })
        .then((res:any) => {
          if (res.status == 200) {
            resolve(res.data.url);
          }
        })
        .catch((reason: AxiosError) => {
          reject(reason);
          throwError(reason);
        });
    })
  }

  // get all documents of candidate by candidate id
  export const getAllDocumentsByCandidateId = (candidate_id?:string)=>{
    const ep = epESign + `document/offer-status/${candidate_id}`;
    return new Promise<any>((resolve, reject) => {
      api
       .get(ep,{ headers: authHeader() })
       .then((res:any) => {
          if (res.status == 200) {
            resolve(res.data);
          }
        })
       .catch((reason: AxiosError) => {
          reject(reason);
        });
    });
  }


  export const markAsTeamCalendar = ( request: { calendar_id: string,calendar_summary: string }) => {
    return new Promise<any>((resolve, reject) => {
      const url = `${epScheduling}calendars/shared-calendar`;
  
      api
        .patch<any>(url, request, { headers: authHeader() })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.data);
          } else {
            reject(new Error(`Unexpected response status: ${res.status}`));
          }
        })
        .catch((reason: AxiosError) => {
          throwError(reason);
        });
      });
    };
  /********************** Ai resume relevacy api***************************/
  export const getRelevancyFeedback = (jd_id:string,candidate_id:string)=>{
    const ep = epCDB + `candidates/relevancy/feedback?jd_id=${jd_id}&candidate_id=${candidate_id}`;
    return new Promise<any>((resolve, reject) => {
      api
       .get(ep,{ headers: authHeader() })
       .then((res:any) => {
          if (res.status == 200) {
            resolve(res.data);
          }
        })
       .catch((reason: AxiosError) => {
          reject(reason);
        });
    });
  }

  export const updateRelevancyFeedback = (req:{jd_id:string,candidate_id:string,comment?:string,feedback?:boolean})=>{
    const ep = epCDB + `candidates/relevancy/feedback`;
    return new Promise<any>((resolve, reject) => {
      api
       .post(ep,req,{ headers: authHeader() })
       .then((res:any) => {
          if (res.status == 200) {
            resolve(res.data);
          }
        })
       .catch((reason: AxiosError) => {
          reject(reason);
        });
    });
  };




  /********************************************  Meeting recording ********************************************/
// Get all meetings
export function getAllMeetings(cardId: string) {
  const ep = `/v1/calendar/meetings/list/card_id/${cardId}`;
  return new Promise<Meetings[]>((resolve, reject) => {
    api
      .get<getAllMeeting>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.meetings);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
}

// Get single meeting
export function getSingleMeeting(meetingId: string) {
  const ep = `/v1/calendar/meetings/id/${meetingId}`;
  return new Promise<Meetings>((resolve, reject) => {
    api
      .get<getMeeting>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.meeting);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
}

// Get prompts templates
export function getPromptsTemplates() {
  const ep = `/v1/calendar/meetings/prompts`;
  return new Promise<PromptTemplates[]>((resolve, reject) => {
    api
      .get<getPromptTemplates>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.prompts);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
}

// Create insights
export function createInsights(promptId: string, botId: string) {
  const ep = `/v1/calendar/meetings/insights?prompt_id=${promptId}&bot_id=${botId}`;
  return new Promise((resolve, reject) => {
    api
      .post(ep, {}, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.insight);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
}

// Get all insights
export function getAllInsights(insightId: string,meetingId:string) {
  const ep = `/v1/calendar/meetings/insights/${meetingId}/${insightId}`;
  return new Promise((resolve, reject) => {
    api
      .get(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.insights);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
}

// Get settings
export function getSettings() {
  const ep = `/v1/calendar/meetings/settings`;
  return new Promise((resolve, reject) => {
    api
      .get(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.settings);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
}


export const createFeedback = (newFeedback: AddFeedbackReq) => {
  const ep = epScheduling +'meetings/feedback';
  return new Promise<feedback>((resolve, reject) => {
    api
      .post<FeedbackRes>(ep, newFeedback, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.feedback);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const editFeedback = (req: EditFeedbackReq) => {
  const ep = epScheduling + `meetings/feedback?id=${req.id}`;
  return new Promise<feedback>((resolve, reject) => {
    api
      .patch<FeedbackRes>(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.feedback);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getFeedbacks = (id: string) => {
  const ep = epScheduling + `meetings/feedback?id=${id}`;
  return new Promise<feedback[]>((resolve, reject) => {
    api
      .get<getFeedbackRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.feedback);
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export function getAllMeetingsUserWise() {
  const ep = epScheduling+`meetings/list`;
  return new Promise<Meetings[]>((resolve, reject) => {
    api
      .get<any>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.meeting);
        }
      })
      .catch((reason: AxiosError) => {
        throwError(reason);
        reject(reason);
      });
  });
}

export const InterviewerLogin = (base64:string) => {
  const ep = epFusion + `login/${base64}`;

  return new Promise<any>((resolve, reject) => {
    api
      .post<{login:loginUser}>(ep, null)
      .then((res) => {
        if (res.status == 200 || res.status == 212) {
          resolve(res.data.login);
        }
      })
      .catch((reason: any) => {
        reject(reason);
      });
  });
};

export const generateInterviewerLink = (base64:string) => {
  const ep = epFusion + `login/secret/regenerate?secret=${base64}`;

  return new Promise<any>((resolve, reject) => {
    api
      .post<any>(ep, null)
      .then((res) => {
        if (res.status == 200 || res.status == 212) {
          resolve(res.data.secret);
        }
      })
      .catch((reason: any) => {
        reject(reason);
      });
  });
};


